// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-modal-textEmail {
  text-align: center;
  width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/components/SendEmailPass/SendEmailPass.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB;EAClB,WAAW,EAAA","sourcesContent":[".sign-modal-textEmail{\n    text-align: center;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
