import React, { useContext } from "react";
import ButtonGradient from "../buttons/ButtonGradient";
import ButtonWhite from "../buttons/buttonWhite/ButtonWhite";
import { Briefcase, CreditCard, ShoppingCart } from "lucide-react";
import { Col, Row, message } from "antd";
import { IoMdHelpCircleOutline } from "react-icons/io";
import "./TotalCart.scss";
import { currenceFormatter, formatPrice } from "../../utils/currenceFormatter";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../utils/serverData";
import CartContext from "../../context/cartContext";
import { modalsContext } from "../../context/modalsContex";
import { universityInfoContext } from "../../context/universityInfoContext";

const TotalCart = ({ totalPrice }) => {
  const { uniId: id } = useParams();

  const navigate = useNavigate();

  const { setLogIn, token } = useContext(modalsContext);
  const cartCtx = useContext(CartContext);
  const { universityCurrency } = useContext(universityInfoContext);

  const gravl_token = localStorage.getItem("gravl_token");

  const handleNavigateItems = () => {
    navigate(`/university/${id}`);
  };

  const handleGenerateQuote = async () => {
    try {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const response = await axios.post(
        `${BACKEND_URL}/client/quote/`,
        {
          options: cartCtx.items.map((item) => ({
            optionId: item.options.id,
            optionIndex: item.options.data.pricing.findIndex((e) => e.selected),
            amount: item.quantity,
          })),
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Time-Zone": userTimeZone,
            ...(token && { Authorization: `Bearer ${token}` }),
          },
          responseType: "blob",
        }
      );

      console.log(response);

      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "quote.pdf";
      console.log(response.headers);
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }

      link.download = fileName;

      // Programmatically trigger a click event on the link to initiate the download
      link.click();

      // Clean up resources
      window.URL.revokeObjectURL(link.href);
      message.success("Quote generated successfully!");
    } catch (error) {
      console.error("An error occurred while generating the quote:", error);
      if (error.response) {
        console.error("Error status:", error.response.status);
        console.error("Error data:", error.response.data);
      } else {
        console.error("Error message:", error.message);
      }
      message.error("Failed to generate the quote. Your cart is empty");
    }
  };

  const handleButtonClick = () => {
    if (gravl_token) {
      // If token exists, navigate to the payment page
      navigate(`/university/${id}/payment`);
    } else {
      // If no token, prompt login
      setLogIn(true);
    }
  };

  const salesTaxRate = 0;
  const salesTax = totalPrice * salesTaxRate;

  const finalTotal = totalPrice + salesTax;

  return (
    <>
      <div className="total-cart">
        <p className="total-cart__title">
          Total: {formatPrice(finalTotal, universityCurrency)}
        </p>
        <Row>
          <Col span={16} className="total-cart__sales">
            <p>Subtotal</p>
          </Col>
          <Col span={8} className="total-cart__price">
            <p>{formatPrice(finalTotal, universityCurrency)}</p>
          </Col>
        </Row>

        <div className="total-cart__btns">
          {cartCtx.items.length > 0 && (
            <ButtonGradient
              text="PROCEED TO PAYMENT"
              icon={<CreditCard className="purchase-icon" size={20} />}
              handleClick={handleButtonClick}
            />
          )}
          {cartCtx.items.length <= 0 && (
            <ButtonWhite
              text="Your Cart is Empty"
              icon={<ShoppingCart className="purchase-icon" size={20} />}
              disabled={true}
            />
          )}
          <ButtonWhite
            text="GENERATE QUOTE"
            icon={<Briefcase className="quote-icon" size={20} />}
            disabled={!token}
            handleClick={handleGenerateQuote}
          />
        </div>
        <div className="total-cart__brows" onClick={handleNavigateItems}>
          Continue browsing
        </div>
      </div>
      <div className="total-info" />
  
      
      
    </>
  );
};

export default TotalCart;
