import React, { useContext } from "react";
import { ChevronRight, Info, CreditCard, MoveLeft } from "lucide-react";
import {useNavigate, useParams} from "react-router-dom"
import { Col, Row, message } from "antd";
import { BACKEND_URL } from "../../../utils/serverData";
import "./PayWithCard.scss";
import ButtonWhite from "../../buttons/buttonWhite/ButtonWhite";
import ButtonGradient from "../../buttons/ButtonGradient";
import { universityInfoContext } from "../../../context/universityInfoContext";
import axios from "axios";

import CartContext from "../../../context/cartContext";

const PayWithCard = () => {
    const { uniId: id } = useParams();
    const navigate= useNavigate();
    const { setOrderId } = useContext(universityInfoContext);
   
    const cartCtx = useContext(CartContext);
    const gravl_token = localStorage.getItem("gravl_token");
    
    const handleNavigate = ()=>{
        navigate(`/university/${id}/cart`);
      }



      const handleConfirm = () => {
      
            const itemsForOrder = cartCtx.items.map((item) => {
                const selectedIndex = item.options.data.pricing.findIndex(
                    (p) => p.selected === true
                );
    
                if (selectedIndex < 0) {
                    console.error("Invalid pricing option selected for item:", item);
                    throw new Error("Cannot find a selected pricing option.");
                }
    
                return {
                    optionId: item.options.id,
                    optionIndex: selectedIndex,
                    amount: item.quantity,
                };
            });
    
            axios
                .post(
                    `${BACKEND_URL}/admin/orders`,
                    { cart: itemsForOrder },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${gravl_token}`,
                        },
                    }
                )
                .then((response) => {
                    setOrderId(response?.data?.data.order?.id);
                    message.success("Order created successfully!");
                    navigate(`/university/${id}/review`)
                    cartCtx.clearCart()
                })
                .catch((error) => {
                    console.error("Error making purchase:", error);
                    message.error("Failed to create order. Please try again.");
                });
      
    };
    

  return (
    <div className="playWithCard">
      <p className="playWithCard-title">Card</p>
      <div className="playWithCard-card">
        <div>
          <p className="playWithCard-card__visa">VISA</p>
          <p className="playWithCard-card__numb">****3656</p>
        </div>
        <div>
          <ChevronRight color="#36C6E6" />
        </div>
      </div>
      <div className="playWithCard-info">
        <Info color="#36C6E6" className="info-icon" />
        <p className="playWithCard-info__text">
          If you want to change a payment method details, please got to your
          account’s <span>payment section.</span>
        </p>
      </div>
      <div className="playWithCard-btns">
        <Row justify="space-between">
          <Col span={8}>
            <ButtonWhite
              text="BACK"
              icon={<MoveLeft className="quote-icon" size={20} />}

              handleClick={handleNavigate}
            />
          </Col>
          <Col span={14}>
            <ButtonGradient
              text="CONFIRM AND PAY"
              icon={<CreditCard className="user-icon" size={20} />}
              handleClick={handleConfirm}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PayWithCard;
