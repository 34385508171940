import React from "react";
import { RouterProvider } from "react-router-dom";
import "./App.scss";
import ItemDetails from "./pages/ItemDetails/ItemDetails";

import Home from "./pages/Home/Home";
import { ModalsProvider } from "./context/modalsContex";
import SignUpOwnerModal from "./components/modals/signUpOwner/SignUpOwnerModal";
import LoginModal from "./components/modals/login/LoginModal";
import { UniversityProvider } from "./context/universityInfoContext";
import InstrumentItems from "./pages/MainLayout/InstrumentItems/InstrumentItems";

import { createBrowserRouter } from "react-router-dom";
import Root from "./Root";
import QuoteGenerateModal from "./components/modals/quote/QuoteGenerateModal";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import Cart from "./pages/Cart/Cart";
import { CartContextProvider } from "./context/cartContext";
import { FilterInstrumentsProvider } from "./context/filterInstrumentsContext";
import OrderPayment from "./pages/Order/OrderPayment";
import ReviewOrder from "./pages/Review/ReviewOrder";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import ReactGA from "react-ga4";
import ResetPass from "./pages/ResetPass/ResetPass";

const developmentGATag = 'G-QLNEJTDJZN';
const productionGATag = 'G-X4C5LV7E8F';
const demoGATag = 'G-R5WN3K1ZCQ';

const GATag =
  process.env.REACT_APP_ENV === "DEV"
    ? developmentGATag
    : process.env.REACT_APP_ENV === "PROD"
    ? productionGATag
    : process.env.REACT_APP_ENV === "DEMO"
    ? demoGATag
    : developmentGATag;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/university/:uniId/facilities/:facilityId",
        element: <InstrumentItems />,
      },
      {
        path: "/university/:uniId/service/:serviceId",
        element: <ItemDetails />,
      },
      {
        path: "/university/:uniId/facilities/:facilityId/service/:serviceId",
        element: <ItemDetails />,
      },
      {
        path: "/university/:uniId/cart",
        element: <Cart />,
      },
      {
        path: "/university/:uniId/payment",
        element: <OrderPayment />,
      },
      {
        path: "/university/:uniId/review",
        element: <ReviewOrder />,
      },
      {
        path: "/university/:uniId",
        element: <Home />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions />,
      },
      {
        path: "/university/:uniId/reset-password",
        element: <ResetPass />,
      },
    ],
  },
]);
function App() {
  ReactGA.initialize(GATag);

  return (
    <ModalsProvider>
      <UniversityProvider>
        <FilterInstrumentsProvider>
          <CartContextProvider>
            <div className="App">
              <div className="main__container">
                <RouterProvider router={router} />
              </div>
            </div>
            <SignUpOwnerModal />
            <LoginModal />
            <QuoteGenerateModal />
          </CartContextProvider>
        </FilterInstrumentsProvider>
      </UniversityProvider>
    </ModalsProvider>
  );
}

export default App;
