import { useContext, useState } from "react";
import Modal from "react-modal";
import "./SignUpOwnerModal.scss";
import { Checkbox, Input } from "antd";
import { modalStyles } from "../helpsModal";
import { modalsContext } from "../../../context/modalsContex";
import { BACKEND_URL } from "../../../utils/serverData";
import axios from "axios";
import { InputField } from "../../inputs/InputField";
import Error from "../../Error/Error";

import ButtonGradient from "../../buttons/ButtonGradient";

const SignUpOwnerModal = () => {
  const { signUpOwnerModal, setSignUpOwnerModal, setSuccessfulSignUp } =
    useContext(modalsContext);

  const [passwordOwner, setPasswordOwner] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);

  function closeModal() {
    setSignUpOwnerModal(false);
  }

  const handleSave = () => {
    if (!emailAddress?.includes("@")) {
      setErrorMessages("Please enter a valid email address");
      return;
    }
    if (passwordOwner !== repeatPassword) {
      setErrorMessages("Passwords do not match");
      return;
    }
    if (!agreeTerms) {
      setErrorMessages("Please accept the terms and conditions");
      return;
    }
    const data = {
      email: emailAddress,
      password: passwordOwner,
      repeatPassword: repeatPassword,
      firstName: firstName,
      lastName: lastName,
      accepted: agreeTerms,
    };

    axios
      .post(`${BACKEND_URL}/auth/signup-user`, data)
      .then((response) => {
        setErrorMessages([]);

        // console.log(response);
        if (response.status === 200) {
          setSuccessfulSignUp(true);
        }
        setEmailAddress("");
        setPasswordOwner("");
        setRepeatPassword("");
        setFirstName("");
        setLastName("");

        closeModal();
      })
      .catch((error) => {
        console.error("Error:!!!!", error?.response?.data);
        // if (error.response.status === 422 || error.response.status === 400) {
        //   const errorShowMessage = error?.response?.data?.errors[0]?.msg;
        //   setErrorMessages(errorShowMessage);
        // }
        console.log(error?.response?.data?.errors[0]?.msg);
        setErrorMessages(error?.response?.data?.errors[0]?.msg);
      });
  };
  const Label = ({ text }) => {
    return <p className="user-modal__label">{text}</p>;
  };
  return (
    <Modal
      isOpen={signUpOwnerModal}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <div className="user-modal">
        <div className="user-modal__close" onClick={closeModal}>
          X
        </div>
        <div className="user-modal__header">
          <h3 className="user-modal__title">Sign Up!</h3>
          <p className="user-modal__provide">
            In order to complete your purchase or generate a quote please create
            an account first.
          </p>
        </div>
        <div className="user-modal__form">
          <div className="user-modal__form name">
            <div className="user-modal__form nameBox">
              <div className="user-modal__input-container">
                <Label text="First Name" />
                <InputField
                  type="text"
                  value={firstName}
                  onChange={({ target }) => setFirstName(target.value)}
                  placeholder="Please enter here..."
                />
              </div>
            </div>
            <div className="user-modal__form nameBox">
              <div className="user-modal__input-container">
                <Label text="Last Name" />
                <InputField
                  type="text"
                  value={lastName}
                  onChange={({ target }) => setLastName(target.value)}
                  placeholder="Please enter here..."
                />
              </div>
            </div>
          </div>
          <div className="user-modal__input-container">
            <Label text="Email" />
            <InputField
              type="email"
              value={emailAddress}
              onChange={({ target }) => setEmailAddress(target.value)}
              placeholder="Please enter here..."
            />
          </div>
          <div className="user-modal__input-container">
            <Label text="Password" />
            <Input.Password
              type="password"
              value={passwordOwner}
              onChange={({ target }) => setPasswordOwner(target.value)}
              placeholder="Please enter here..."
              className="user-modal__passInput"
            />
          </div>
          <ul className="user-modal__list">
            <li>At least 8 characters—the longer, the better</li>
            <li>A mixture of both uppercase and lowercase letters</li>
            <li>A mixture of letters and numbers</li>
            <li>Inclusion of at least one special character.</li>
          </ul>
          <div className="user-modal__input-container">
            <Label text="Confirm Password" />
            <Input.Password
              type="password"
              value={repeatPassword}
              onChange={({ target }) => setRepeatPassword(target.value)}
              placeholder="Please enter here..."
              className="user-modal__passInput"
              style={{ backgroundColor: "none" }}
            />
          </div>
          {/* </ConfigProvider> */}
        </div>

        <Error errorMessages={errorMessages} />
        {/*      
        <ButtonsModal
          confirm="Sign Up"
          cancel="Cancel"
          handleSave={handleSave}
          closeModal={closeModal}
        /> */}
        <div className="user-modal__terms">
          <Checkbox
            onChange={(e) => setAgreeTerms(e.target.checked)}
            checked={agreeTerms}
          >
            By clicking here, I state that I have read and understood the &nbsp;
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "underline", color: "blue" }}
            >
              terms and conditions
            </a>
          </Checkbox>
        </div>

        <div className="user-modal__button-container">
          <ButtonGradient text="CONTINUE" handleClick={handleSave} />
        </div>
      </div>
    </Modal>
  );
};

export default SignUpOwnerModal;
