import React, { useContext } from "react";
import { ShoppingCart, Phone, Briefcase } from "lucide-react";
import { Spin } from "antd";
import ButtonGradient from "../buttons/ButtonGradient";
import ButtonWhite from "../buttons/buttonWhite/ButtonWhite";
import { modalsContext } from "../../context/modalsContex";
import { universityInfoContext } from "../../context/universityInfoContext";

const ButtonsSection = ({ handlePurchase, serviceId, index, cartLoad, title, details }) => {
  const { token, setQuoteGenerateModal } =
    useContext(modalsContext);
  const { setItemLabInstrument, setItemLabInstrumentName, setItemIndex } = useContext(universityInfoContext);

  const handleOpenQuoteModal = () => {
    setQuoteGenerateModal(true);
    setItemLabInstrument(details.options.id);
    setItemLabInstrumentName(title);
    setItemIndex(index);
  };

  return (
    <>
      <div className="itemDetails-instrument__buttons">
        {cartLoad ? (
          <div className="itemCard__button">
            <Spin />
            &nbsp; Adding..
          </div>
        ) : (
          <div className="itemDetails-instrument__buttons purchase">
            <ButtonGradient
              text="Add to Cart"
              icon={<ShoppingCart className="purchase-icon" size={20} />}
              handleClick={handlePurchase}
            />
          </div>
        )}
        <div className="itemDetails-instrument__buttons schedule">
          <ButtonWhite
            text="Schedule a CALL"
            icon={<Phone className="phone-icon" size={20} />}
          />
        </div>
      </div>
      <div className="itemDetails-instrument__quotes">
        <div className="itemDetails-instrument__quotes generate">
          <ButtonWhite
            text="GENERATE a QUOTE"
            icon={<Briefcase className="quote-icon" size={20} />}
            disabled={!token}
            handleClick={handleOpenQuoteModal}
          />
        </div>
        <div className="itemDetails-instrument__quotes sign"></div>
      </div>
    </>
  );
};

export default ButtonsSection;
