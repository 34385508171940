import React from "react";
import { Checkbox } from "antd";
import { ChevronDown, ChevronRight, Info } from "lucide-react";
const CheckboxGroup = Checkbox.Group;

const LibraryPreparation = ({
  labLength,
  onCheckAllLabs,
  checkAllLibraries,
  handleVisibleLabs,
  visibleLabs,
  labOptions,
  checkedListLabs,
  onChangeLab
}) => {
  return (
    <>
      <div className="filter-sequencing__boxes">
        <Checkbox
          indeterminate={labLength}
          onChange={onCheckAllLabs}
          checked={checkAllLibraries}
        >
          <div className="filter-sequencing__boxTitle">
          Library Preparation
          </div>
          <Info className="filter-sequencing__icon-info" size={16} />
        </Checkbox>
        <div onClick={handleVisibleLabs} className="filter-sequencing__icon">
        {!visibleLabs && (<ChevronRight className="filter-sequencing__icon-arrow" />)}
        {visibleLabs && (<ChevronDown className="filter-sequencing__icon-arrow" />)}
        </div>
      </div>
      <div className="filter-sequencing__groups">
        {visibleLabs && (
          <CheckboxGroup
            options={labOptions}
            value={checkedListLabs}
            onChange={onChangeLab}
          />
        )}
      </div>
    </>
  );
};

export default LibraryPreparation;
