import React, { useContext } from "react";
import "./Footer.scss";
import { universityInfoContext } from "../../context/universityInfoContext";

const Footer = () => {
  const { universityInfo } = useContext(universityInfoContext);

  return (
    <div className="footer">
      <div className="footer__logo">
        <p>{universityInfo?.name}</p>
        <img
          src={universityInfo?.logoLink}
          alt="logo"
          className="footer__logo-logoUni"
        />
      </div>

      <div className="footer__wrapp">
        <div className="footer__wrapp-left"></div>

        <div className="footer__wrapp-right">
          <div className="footer__wrapp-data">
            {universityInfo?.details?.email && (
              <>
                <p className="footer__link">Contacts:</p>

                <p className="footer__link footer__link--active">
                  {universityInfo?.details?.email}
                </p>
              </>
            )}
          </div>

          <div className="footer__wrapp-data">
            {universityInfo?.details?.address && (
              <>
                <p className="footer__link">Address:</p>
                <p className="footer__link footer__link--active">
                  {universityInfo?.details?.address}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
