import React from "react";
import { Linkedin } from "lucide-react";
import "./TeamCard.scss";

const TeamCard = ({ person }) => {
  return (
    <div className="team-card">
    <div className="team-card__info">
      <img src={person.image} alt="img" className="team-card__image" />
      
        <h2 className="team-card__name">{person.name}</h2>
        <p className="team-card__title">{person.position}</p>
        <p className="team-card__text">{person.description}</p>
      </div>

      <a
        href={person.linkedin}
        target="_blank"
        rel="noopener noreferrer"
        className="team-card__link"
      >
        <Linkedin size={24} color="#36c6e6" />
      </a>
    </div>
  );
};

export default TeamCard;
