import React from "react";
import "./ButtonGradient.scss";
const ButtonGradient = ({ text, icon, handleClick, disabled }) => {
    const buttonClasses = disabled ? "btn-gradient disabled" : "btn-gradient";
  return (
    <div className={buttonClasses}  onClick={handleClick}>
      {icon}
      {text}
    </div>
  );
};

export default ButtonGradient;
