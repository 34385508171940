import React, { useContext } from "react";
import { Checkbox, Col, InputNumber, Row, Slider } from "antd";
import { ChevronDown, ChevronRight } from "lucide-react";
import "../FilterSequencing.scss";
import { filterInstrumentsContext } from "../../../../context/filterInstrumentsContext";

const OutPut = ({
  handleVisibleOutput,
  handleCheckboxChangeOutput,

  onChangeOutput,
}) => {
  const {
    filterData,
    inputValueOutput,
    visibleOutput,
    checkOutput,
    setInputValueOutput,
  } = useContext(filterInstrumentsContext);

  const handleMinInputChange = (value) => {
    setInputValueOutput((prev) => ({
      ...prev,
      min: value,
    }));
  };

  const handleMaxInputChange = (value) => {
    setInputValueOutput((prev) => ({
      ...prev,
      max: value,
    }));
  };

  return (
    <div className="filter-sequencing__readLengthBox">
      <div className="filter-sequencing__readLength">
        <Checkbox onChange={handleCheckboxChangeOutput} checked={checkOutput}>
          <div className="filter-sequencing__boxTitle">Output (Gb)</div>
        </Checkbox>
        <div onClick={handleVisibleOutput} className="filter-sequencing__icon">
          {checkOutput ? (
            visibleOutput ? (
              <ChevronDown className="filter-sequencing__icon-arrow" />
            ) : (
              <ChevronRight className="filter-sequencing__icon-arrow" />
            )
          ) : null}
        </div>
      </div>

      {visibleOutput && (
        <Col className="filter-sequencing__readLengthBox--visibleRange">
          <Col span={24}>
            {checkOutput && (
              <Slider
                range
                min={filterData?.outputRange?.min}
                max={filterData?.outputRange?.max}
                onChange={onChangeOutput}
                value={
                  inputValueOutput
                    ? [inputValueOutput?.min, inputValueOutput?.max]
                    : [filterData.outputRange.min, filterData.outputRange.max]
                }
              />
            )}
          </Col>
          {checkOutput && (
            <Row className="filter-sequencing__range">
              <InputNumber
                value={inputValueOutput?.min ?? filterData.outputRange?.min}
                onChange={(value) => handleMinInputChange(value)}
              />
              <InputNumber
                value={inputValueOutput?.max ?? filterData.outputRange.max}
                onChange={(value) => handleMaxInputChange(value)}
              />
            </Row>
          )}
        </Col>
      )}
    </div>
  );
};

export default OutPut;
