import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../utils/serverData";
import GridCardInstruments from "../gridCardinstruments/GridCardInstruments";
import "./OtherUniversity.scss";
const OtherUniversity = ({facilityName}) => {
  const { facilityId: facId } = useParams();
  const [device, setDevice] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  useEffect(() => {
    if (!dataFetched) {
      axios

        .get(`${BACKEND_URL}/client/facilities/${facId}/services`)
        .then((response) => {
          const data = response.data;
          //  console.log("instruments", data)
          setDevice(data.sort(() => Math.random() - 0.5)?.slice(0,4));
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setDataFetched(true);
        });
    }
  }, [facId, dataFetched, setDevice]);
  // console.log(device);
  return (
    <div className="other-university">
      {device.length > 0 ? (
        <h2 className="other-university__title">
        Other services at {facilityName}
        </h2>
      ) : null}

      <div className="other-university__items">
        {device?.map((product, index) => (
          <GridCardInstruments
            key={index}
            img={product.device.imageUrl}
            title={product.device.name}
            description={product.service.description}
            price={product.minimalPrice}
            product={product}
            options={product.options}
          />
        ))}
      </div>
    </div>
  );
};

export default OtherUniversity;
