import { createContext, useState } from "react";

export const modalsContext = createContext();

export function ModalsProvider({ children }) {
  //open sign up modal
  const [signUpOwnerModal, setSignUpOwnerModal] = useState(false);
  // open login modal
  const [logIn, setLogIn] = useState(false);
  //set response after sign up
  const [successfulSignUp, setSuccessfulSignUp] = useState(false);
  const [token, setToken] = useState("");
  const [quoteGenerateModal, setQuoteGenerateModal] = useState(false);
  // console.log(quoteGenerateModal)
  const [emailAddress, setEmailAddress] = useState("");
  const [userProfile, setUserProfile] = useState();

  return (
    <modalsContext.Provider
      value={{
        signUpOwnerModal,
        setSignUpOwnerModal,
        setLogIn,
        logIn,
        setSuccessfulSignUp,
        successfulSignUp,
        token,
        setToken,
        quoteGenerateModal,
        setQuoteGenerateModal,
        setEmailAddress,
        emailAddress,
        userProfile,
        setUserProfile,
      }}
    >
      {children}
    </modalsContext.Provider>
  );
}
