import React, { useEffect, useState } from "react";
import { Skeleton } from "antd";

const ItemHeader = ({ isLoading, setIsLoading, props }) => {
  const [showImage, setShowImage] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [setIsLoading]);
  const handleImageLoaded = () => {
    setIsLoading(false);
  };
  return (
    <>
      
      {isLoading && <Skeleton.Image active />}
      {showImage && (
        <img
          src={props.img}
          alt="img"
          className="itemCard__img"
          onLoad={handleImageLoaded}
          style={{ display: isLoading ? "none" : "block" }}
        />
      )}
      <div className="itemCard__info">
        <h2 className="itemCard__title">{props.title}</h2>
        <p className="itemCard__description">{props.description}</p>
      </div>
    </>
  );
};

export default ItemHeader;
