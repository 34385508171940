import React, { useContext, useEffect, useState } from "react";
import { Popover, Spin } from "antd";
import { ChevronDown, ShoppingCart, Briefcase, Settings } from "lucide-react";
import { Link, useParams, useNavigate } from "react-router-dom";

import "./Item.scss";

import { modalsContext } from "../../../context/modalsContex";
import { universityInfoContext } from "../../../context/universityInfoContext";
import ButtonWhite from "../../buttons/buttonWhite/ButtonWhite";
import OptionTypeCard from "../../optionTypeCard/OptionTypeCard";
import ItemHeader from "./components/ItemHeader";
import CartContext from "../../../context/cartContext";
import { formatPrice, formatReads } from "../../../utils/currenceFormatter";

import { InputNumber } from "antd";

export const formatReadType = (readType) => {
  const parts = readType?.split("x");

  if (parts?.length === 2) {
    return `${parts[0]}x = ${parts[1]}`;
  }

  return readType;
};

const Item = (props) => {
  const { uniId: id, facilityId: facId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [cartLoad, setCartLoad] = useState(false);

  const { token, setQuoteGenerateModal } = useContext(modalsContext);
  const {
    setItemLabInstrument,
    setItemLabInstrumentName,
    setItemIndex,
    universityCurrency,
  } = useContext(universityInfoContext);
  const cartCtx = useContext(CartContext);

  const [indexType, setIndexType] = useState(null);
  const [selectedReadType, setSelectedReadType] = useState("");
  const [selectedPrice, setSelectedPrice] = useState();
  const [selectedOutput, setSelectedOutput] = useState();
  const [selectedAmount, setSelectedAmount] = useState();
  const [selectedReads, setSelectedReads] = useState();
  const [selectedNameControll, setSelectedNameControll] = useState();
  const [itemAmount, setItemAmount] = useState(1);

  const handleIndexChange = (index) => {
    setIndexType(index);
  };

  const title = <h2 className="option-popover__title">Available Options</h2>;

  useEffect(() => {
    if (props?.options?.data?.pricing) {
      setIndexType(props?.options?.data?.pricing[0].id);
    }
  }, [props.options]);

  useEffect(() => {
  
    if (props?.options?.data?.pricing.find(i => i.id === indexType)) {
   
      const selectedOption = props?.options?.data?.pricing.find(i => i.id === indexType)
      setSelectedReadType(
        selectedOption?.data?.readType || selectedOption.data.coverage
      );
      setSelectedPrice(selectedOption.price);
      setSelectedOutput(selectedOption.data.output);
      setSelectedAmount(selectedOption.data.amount);
      setSelectedReads(selectedOption.data.reads);
      setSelectedNameControll(selectedOption.data.name);
    }
    // setIndexType(indexType);
  }, [props.options, indexType]);

  const content = (
    <div className="run-item">
      {title}
      <OptionTypeCard
        props={props}
        indexType={indexType}
        handleIndexChange={handleIndexChange}
        setSelectedReadType={setSelectedReadType}
        setSelectedPrice={setSelectedPrice}
        setSelectedOutput={setSelectedOutput}
        setSelectedAmount={setSelectedAmount}
        selectedAmount={selectedAmount}
        selectedNameControll={selectedNameControll}
      />
    </div>
  );

  const handleOpenQuoteModal = () => {
    setQuoteGenerateModal(true);
    setItemLabInstrument(props.product.options.id);
    setItemLabInstrumentName(props.title);
    setItemIndex(indexType);
  };

  const capitalizedType =
    props?.options?.data?.per?.type?.charAt(0).toUpperCase() +
    props?.options?.data?.per?.type?.slice(1);
  let capitalizedTypeWithPlural = capitalizedType;

  if (props?.options?.data?.per?.amount && props.options.data.per.amount > 1) {
    capitalizedTypeWithPlural += "s";
  }

  const serviceType = props.product.service.type;

  const handleSelectLink = () => {
    if (props.isHome) {
      navigate(`/university/${id || 1}/service/${props.product.service.id}`);
    } else {
      navigate(
        `/university/${id || 1}/facilities/${facId}/service/${
          props.product.service.id
        }`
      );
    }
  };

  function handleAddToCart() {
    setCartLoad(true);
    setTimeout(() => {
  
      cartCtx.addItem(props.product, indexType, itemAmount);
      setCartLoad(false);
    }, 500);
  }

  return (
    <div className="itemCard">
      <Link onClick={handleSelectLink}>
        <ItemHeader
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          props={props}
        />
      </Link>
      <div className="itemCard__box">
        {serviceType !== "DataAnalysis" && (
          <div className="itemCard__box-flow">
            <div className="itemCard__infoOption">
              <Popover
                placement="top"
                content={content}
                arrow={false}
                fresh={true}
                destroyTooltipOnHide={true}
                className="grid-instruments__option-popover"
                // open={true}
                overlayKey={`popover-${props.id}`}
              >
                <div className="grid-instruments__option-text">
                  <Settings className="grid-instruments__option-icon" />
                  {props.options.data.pricing.length === 1
                    ? `${props.options.data.pricing.length} option`
                    : `${props.options.data.pricing.length} options`}
                </div>

                <ChevronDown className="grid-instruments__option-icon" />
              </Popover>
            </div>
          </div>
        )}

        <Link onClick={handleSelectLink}>
          <div className="itemCard__box-types">
            {serviceType === "Sequencing" ? (
              <>
                <div className="itemCard__box-types item">
                  <div className="itemCard__box-types item-title">
                    Read Type
                  </div>
                  <div className="itemCard__box-types item-info">
                    {selectedReadType}
                  </div>
                </div>
                <div className="itemCard__box-types item">
                  {selectedReads && (
                    <>
                      <div className="itemCard__box-types item-title">
                        Reads
                      </div>
                      <div className="itemCard__box-types item-info">
                        {formatReads(selectedReads)}
                      </div>
                    </>
                  )}
                </div>
                <div className="itemCard__box-types item">
                  {selectedOutput && (
                    <>
                      <div className="itemCard__box-types item-title">
                        Output
                      </div>
                      <div className="itemCard__box-types item-info">
                        {selectedOutput}Gb
                      </div>
                    </>
                  )}
                </div>
                <div className="itemCard__box-types item">
                  {props.product.service.details.flowCell && (
                    <>
                      <div className="itemCard__box-types item-title">
                        Flow Cell
                      </div>
                      <div className="itemCard__box-types item-info">
                        {props.product.service.details.flowCell}
                      </div>
                    </>
                  )}
                  {props.product.service.details.lane && (
                    <>
                      <div className="itemCard__box-types item-title">Lane</div>
                      <div className="itemCard__box-types item-info">
                        {props.product.service.details.lane}
                      </div>
                    </>
                  )}
                </div>
                <div className="itemCard__box-types item">
                  <div className="itemCard__box-types item-title">
                    Price/
                    {props?.options?.data?.per?.amount !== 1 &&
                      props?.options.data.per.amount}
                    {capitalizedTypeWithPlural}
                  </div>
                  <div className="itemCard__box-types item-info">
                    {formatPrice(selectedPrice, universityCurrency)}
                  </div>
                </div>
              </>
            ) : null}

            {serviceType === "AllInOne" ? (
              <>
                <div className="itemCard__box-types item">
                  {selectedReads && (
                    <>
                      <div className="itemCard__box-types item-title">
                        Reads
                      </div>
                      <div className="itemCard__box-types item-info">
                        {formatReads(selectedReads)}
                      </div>
                    </>
                  )}
                </div>

                <div className="itemCard__box-types item">
                  {selectedReadType && (
                    <>
                      <div className="itemCard__box-types item-title">
                        Coverage
                      </div>
                      <div className="itemCard__box-types item-info">
                        {selectedReadType}={selectedOutput}Gb
                      </div>
                    </>
                  )}
                </div>

                <div className="itemCard__box-types item">
                  <div className="itemCard__box-types item-title">
                    Price/
                    {props?.options?.data?.per?.amount !== 1 &&
                      props?.options.data.per.amount}
                    {capitalizedTypeWithPlural}
                  </div>
                  <div className="itemCard__box-types item-info">
                    {formatPrice(selectedPrice, universityCurrency)}
                  </div>
                </div>
              </>
            ) : null}

            {serviceType === "LibraryPreparation" ? (
              <>
                {props.product.service.details.material ? (
                  <div className="grid-instruments__box-types item">
                    <>
                      <div className="grid-instruments__box-types item-title">
                        Material
                      </div>
                      <div className="grid-instruments__box-types item-info">
                        {props.product.service.details.material}
                      </div>
                    </>
                  </div>
                ) : null}
                {props.product.service.details.minInput ? (
                  <div className="grid-instruments__box-types item">
                    <div className="grid-instruments__box-types item-title">
                      Min.Input
                    </div>
                    <div className="grid-instruments__box-types item-info">
                      {props.product.service.details.minInput}
                    </div>
                  </div>
                ) : null}
                <div className="itemCard__box-types item">
                  <div className="itemCard__box-types item-title">
                    Price/
                    {props?.options?.data?.per?.amount !== 1 &&
                      props?.options.data.per.amount}
                    {capitalizedTypeWithPlural}
                  </div>
                  <div className="itemCard__box-types item-info">
                    {formatPrice(selectedPrice, universityCurrency)}
                  </div>
                </div>
              </>
            ) : null}

            {serviceType === "QualityControlAndSampleHandling" ? (
              <>
                <div className="itemCard__box-types item"></div>
                <div className="itemCard__box-types item"></div>
                <div className="itemCard__box-types item">
                  <div className="itemCard__box-types item-title">
                    Price/
                    {props?.options?.data?.per?.amount !== 1 &&
                      props?.options.data.per.amount}
                    {capitalizedTypeWithPlural}
                  </div>
                  <div className="itemCard__box-types item-info">
                    {formatPrice(selectedPrice, universityCurrency)}
                  </div>
                </div>
              </>
            ) : null}

            {serviceType === "DataAnalysis" ? (
              <>
                <div className="itemCard__box-types item"></div>
                <div className="itemCard__box-types item"></div>
                <div className="itemCard__box-types itemPrice">
                  <div className="itemCard__box-types item-title">
                    Price/
                    {props?.options?.data?.per?.amount !== 1 &&
                      props?.options.data.per.amount}
                    {capitalizedTypeWithPlural}
                  </div>
                  <div className="itemCard__box-types item-info">
                    {formatPrice(selectedPrice, universityCurrency)}
                  </div>
                </div>
              </>
            ) : null}

            {serviceType === "BiomarkerPanels" ? (
              <>
                {props?.product?.service?.details["Product Number"] ? (
                  <div className="itemCard__box-types item">
                    <div className="itemCard__box-types item-title">
                      Product Code
                    </div>
                    <div className="itemCard__box-types item-info">
                      {props?.product?.service?.details["Product Number"]}
                    </div>
                  </div>
                ) : null}
                {props?.product?.service?.details["Manufacturer"] ? (
                  <div className="itemCard__box-types item">
                    <div className="itemCard__box-types item-title">
                    Manufacturer
                    </div>
                    <div className="itemCard__box-types item-info">
                      {props?.product?.service?.details["Manufacturer"]}
                    </div>
                  </div>
                ) : null}
                {props?.product?.service?.details["Samples / Full Panel"] ? (
                  <div className="itemCard__box-types item">
                    <div className="itemCard__box-types item-title">
                      Samples / Full Panel
                    </div>
                    <div className="itemCard__box-types item-info">
                      {props?.product?.service?.details["Samples / Full Panel"]}
                    </div>
                  </div>
                ) : null}
                <div className="itemCard__box-types item">
                  <div className="itemCard__box-types item-title">
                    Price/
                    {props?.options?.data?.per?.amount !== 1 &&
                      props?.options.data.per.amount}
                    {capitalizedTypeWithPlural}
                  </div>
                  <div className="itemCard__box-types item-info">
                    {formatPrice(selectedPrice, universityCurrency)}
                  </div>
                </div>
              </>
            ) : null}
            <div className="itemCard__box-types item">
              <>
                <div className="itemCard__box-types ready-title">Ready in</div>
                <div className="itemCard__box-types ready-info">
                  {props?.product?.service?.details?.readyIn}&nbsp;
                  {props?.product?.service?.details?.readyIn === 1
                    ? "day"
                    : "days"}
                </div>
              </>
            </div>
          </div>
        </Link>

        <div className="itemCard__box-buttons">
          {cartLoad ? (
            <div className="itemCard__button">
              <Spin />
              &nbsp; Adding..
            </div>
          ) : (
            <div className="itemCard__button" onClick={handleAddToCart}>
              <ShoppingCart className="itemCard__icon" />
              Add to Cart
            </div>
          )}
          <div className="itemCard__box-amount">
            <InputNumber
              min={1}
              max={10}
              controls={true}
              defaultValue={1}
              style={{ width: 50 }}
              value={itemAmount}
              onChange={(amount) => setItemAmount(amount)}
            />
          </div>
          <div className="itemCard__generateQuote">
            <ButtonWhite
              text="GENERATE a QUOTE"
              icon={<Briefcase className="quote-icon" size={20} />}
              disabled={!token}
              handleClick={handleOpenQuoteModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
