import React from 'react';


export const InputField = ({ type, value, onChange,placeholder }) => {
  return (
    <input
      type={type}
      className="sign-modal__input"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};