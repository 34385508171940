import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { X } from "lucide-react";
import file from "../../../assets/images/file.png";
import "./QuoteGenerateModal.scss";
import { modalsContext } from "../../../context/modalsContex";
import { modalStyles } from "../helpsModal";
import ButtonWhite from "../../buttons/buttonWhite/ButtonWhite";
import ButtonGradient from "../../buttons/ButtonGradient";
import IsReadyQuote from "./isReady/IsReadyQuote";


const QuoteGenerateModal = () => {
  const {
    quoteGenerateModal,
    setQuoteGenerateModal,
    emailAddress,
    setToken,
    setLogIn,
    setUserProfile
  } = useContext(modalsContext);

  const [isReadyQuote, setIsReadyQuote] = useState(false);
  function closeModal() {
    setQuoteGenerateModal(false);
    setIsReadyQuote(false);
  }
  
  const userMail = localStorage.getItem("user_email");

  const handleLogOut = () => {
    localStorage.removeItem("gravl_token");
    localStorage.removeItem("user_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("user_email");
    setToken("");
    setUserProfile("")
    setQuoteGenerateModal(false);
    setLogIn(true);
  };

  const handleCreateQuote = () => {
   
    setIsReadyQuote(true);
  };
  return (
    <Modal
      isOpen={quoteGenerateModal}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <div onClick={closeModal} className="quote-modal__icon">
        <X />
      </div>
      {isReadyQuote ? (
        <IsReadyQuote closeModal={closeModal} userMail={userMail} />
      ) : (
        <div className="quote-modal">
          <img src={file} alt="file-logo" className="quote-modal__img" />
          <h3 className="quote-modal__title">Generate a Quote</h3>
          <p className="quote-modal__text">
            Hello. You can generate a quote for the services you selected your
            self using this tool. We will gather the items you have already
            selected.
          </p>
          <p className="quote-modal__sign">
            You signed in as <b>{emailAddress ? emailAddress : "__ "}</b>.
          </p>
          <p className="quote-modal__sign">
            Not you? <span onClick={handleLogOut}>Sign out</span>
          </p>
          <div className="quote-modal__btns">
            <div className="quote-modal__btnCancel">
              <ButtonWhite text="CANCEL" handleClick={closeModal} />
            </div>
            <div className="quote-modal__btnCont">
              <ButtonGradient text="Continue" handleClick={handleCreateQuote} />
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default QuoteGenerateModal;
