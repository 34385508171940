import React, { useState } from "react";
import { MoveRight } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import "./OrderPayment.scss";
import PayWithCard from "../../components/orderPayment/payWithCard/PayWithCard";
import UploadPo from "../../components/orderPayment/uploadPo/UploadPo";
import ReactGA from 'react-ga4';


const OrderPayment = () => {
  const { uniId: id } = useParams();

  ReactGA.send({
    hitType: "pageview",
    page: `/university/${id}/payment`,
    title: "Order Payment Page"
  })

  const [activeTab, setActiveTab] = useState(1);
  const navigate = useNavigate();

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleNavigate = () => {
    navigate(`/university/${id}/cart`);
  };
  return (
    <div className="payment">
      <div className="payment-bread">
        <div className="payment-bread__cart" onClick={handleNavigate}>
          Cart <MoveRight color="#C4C4C4" className="move-icon" />
        </div>
        Order Payment
      </div>
      <h2 className="payment-title">Payment</h2>
      <p className="payment-text">
        In order to complete your order please upload a purchase order (PO) in
        the space below.
      </p>
      <div className="tabs-container">
        <div className="tabs">
          <div
            className={`tab ${activeTab === 1 ? "active" : ""}`}
            onClick={() => handleTabClick(1)}
          >
            UPLOAD A PO
          </div>
        </div>
        <div className="tab-content">
          {activeTab === 0 && (
            <>
              <PayWithCard />
            </>
          )}
          {activeTab === 1 && (
            <>
              <UploadPo />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderPayment;
