import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, message } from "antd";
import axios from "axios";
import { CreditCard, MoveLeft, Trash2 } from "lucide-react";
import { GoPaperclip } from "react-icons/go";
import { BACKEND_URL } from "../../../utils/serverData";
import "./UploadPo.scss";
import ButtonWhite from "../../buttons/buttonWhite/ButtonWhite";
import ButtonGradient from "../../buttons/ButtonGradient";
import { FileUploader } from "react-drag-drop-files";
import { universityInfoContext } from "../../../context/universityInfoContext";
import { modalsContext } from "../../../context/modalsContex";
import CartContext from "../../../context/cartContext";

const fileTypes = ["PDF", "DOC"];
const UploadPo = () => {
  const { uniId: id } = useParams();
  const navigate = useNavigate();
  const { setOrderId } = useContext(universityInfoContext);
  const { setLogIn } = useContext(modalsContext);
  const cartCtx = useContext(CartContext);
  const gravl_token = localStorage.getItem("gravl_token");

  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleNavigate = () => {
    navigate(`/university/${id}/cart`);
  };

  const handleChange = (file) => {
    if (file) {
      if (file.size > 20 * 1024 * 1024) {
        setErrorMessage("File size exceeds the maximum limit of 20MB.");
      } else {
        setFile(file);
        setErrorMessage("");
      }
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length > maxLength) {
      const extension = fileName.split(".").pop();
      const fileNameWithoutExtension = fileName.replace(`.${extension}`, "");
      const truncatedLength = Math.floor(
        (maxLength - extension.length - 5) / 2
      ); // Subtracting 5 for the '...' and extension
      const firstPart = fileNameWithoutExtension.substring(0, truncatedLength);
      const lastPart = fileNameWithoutExtension.substring(
        fileNameWithoutExtension.length - truncatedLength
      );
      return `${firstPart}...${lastPart}.${extension}`;
    }
    return fileName;
  };

  const handleConfirm = () => {
    if (gravl_token) {
      const itemsForOrder = cartCtx.items.map((item) => {
        const selectedIndex = item.options.data.pricing.findIndex(
          (p) => p.selected === true
        );

        if (selectedIndex < 0) {
          console.error("Invalid pricing option selected for item:", item);
          throw new Error("Cannot find a selected pricing option.");
        }

        return {
          optionId: item.options.id,
          optionIndex: selectedIndex,
          amount: item.quantity,
        };
      });

      const formData = new FormData();

      console.log(file);
      if (file) {
        formData.append("file", file);
      }

      formData.append("cart", JSON.stringify(itemsForOrder));

      axios
        .post(`${BACKEND_URL}/admin/orders`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${gravl_token}`,
          },
        })
        .then((response) => {
          setOrderId(response?.data?.data.order?.id);
          message.success("Order created successfully!");
          navigate(`/university/${id}/review`);
          cartCtx.clearCart()
        })
        .catch((error) => {
          console.error("Error making purchase:", error);
          message.error("Failed to create order. Please try again.");
        });
    } else {
      setLogIn(true);
    }
  };
  return (
    <div className="uploadPo">
      <p className="uploadPo-title">You can upload your PO below. </p>
      <p className="uploadPo-text">
        Once the PO is verified we will notify you and your order will begin
        processing. You will be notified via email.
      </p>

      {!file && (
        <FileUploader
          handleChange={handleChange}
          name="file"
          types={fileTypes}
          maxSize={5 * 1024 * 1024}
        >
          <div className="uploadPo-clipBox">
            <GoPaperclip className="clip-icon" />
            <p>
              <b>Drag-n-drop file here or click to select from your device</b>
            </p>
            <p>Supported file formats: pdf, docx. Up to 5mb.</p>
          </div>
        </FileUploader>
      )}
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      {file && (
        <Row className="uploadPo-fileBox" align="middle">
          <Col span={2}>
            <GoPaperclip className="clipFile-icon" />
          </Col>
          <Col span={20}>{truncateFileName(file.name, 30)}</Col>
          <Col span={2} onClick={handleRemoveFile}>
            <Trash2 color="#cecece" className="trash-icon" />
          </Col>
        </Row>
      )}
      <div className="uploadPo-btns">
        <Row justify="space-between">
          <Col span={8}>
            <ButtonWhite
              text="BACK"
              icon={<MoveLeft className="quote-icon" size={20} />}
              handleClick={handleNavigate}
            />
          </Col>
          <Col span={14}>
            <ButtonGradient
              text="CONFIRM AND PROCEED"
              icon={<CreditCard className="user-icon" size={20} />}
              handleClick={file ? handleConfirm : null}
              disabled={!file}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UploadPo;
