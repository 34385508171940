import React, { useContext, useEffect, useState } from "react";
import logo from "../../assets/images/gravlogo.png";
import { Spin } from "antd";
import { Dot, ShoppingCart, UserRoundPlus } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { BACKEND_URL } from "../.././utils/serverData";
import "./Header.scss";
import { modalsContext } from "../../context/modalsContex";
import ButtonGradient from "../buttons/ButtonGradient";
import ButtonWhite from "../buttons/buttonWhite/ButtonWhite";
import { filterInstrumentsContext } from "../../context/filterInstrumentsContext";
import { universityInfoContext } from "../../context/universityInfoContext";
import CartContext from "../../context/cartContext";
import { setDomianCookie } from "../../utils/cookiesUtils";

const Header = () => {
  const {
    setSignUpOwnerModal,
    setLogIn,
    token,
    setToken,
    successfulSignUp,

    setUserProfile,
  } = useContext(modalsContext);
  const cartCtx = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(true);
  const { setSearchedInstruments, setNotFound } = useContext(
    universityInfoContext
  );

  const { setSearch, cleanFilters } = useContext(filterInstrumentsContext);
  const { uniId: id } = useParams();
  const handleOpenModal = () => {
    setSignUpOwnerModal(true);
  };
  const handleOpenLogin = () => {
    setLogIn(true);
  };
  const navigate = useNavigate();

 

  const handleClick = () => {
    setSearch("");
    cleanFilters();
    setNotFound(false);
    setSearchedInstruments([]);
    if(id) {
      navigate(`/university/${id}`);
    } else {
      navigate(`/university/1`);
    }
  };

  const userName = localStorage.getItem("user_name");
  const userLastName = localStorage.getItem("last_name");

  const initials = userName?.charAt(0) + userLastName?.charAt(0);

  const handleCartNavigate = () => {
    navigate(`/university/${id}/cart`);
  };

  useEffect(() => {
    const gravl_token = localStorage.getItem("gravl_token");
    const user_name = localStorage.getItem("user_name");
    const last_name = localStorage.getItem("last_name");
    const user_email = localStorage.getItem("user_email");

    if (gravl_token) {
      setIsLoading(true);
      fetch(`${BACKEND_URL}/auth`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${gravl_token}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
          if (data.status === "success") {
            localStorage.setItem("user_name", user_name);
            localStorage.setItem("last_name", last_name);
             localStorage.setItem("user_email", user_email);
            setUserProfile(data.data);
            setToken(gravl_token);
          } else {
            localStorage.removeItem("gravl_token");
            localStorage.removeItem("user_name");
            localStorage.removeItem("last_name");
            localStorage.removeItem("user_email");
          }
          setIsLoading(false);
          // console.log("is not loading");
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [setToken, setUserProfile]);
  const handleLogOut = () => {
    localStorage.removeItem("gravl_token");
    localStorage.removeItem("user_name");
    localStorage.removeItem("last_name");
    localStorage.removeItem("user_email");
    setToken("");
    setUserProfile("");
  };

  const handleRedirect = () => {
    const redirectLink = 
    process.env.REACT_APP_ENV === "DEV" ? "https://admin-dev.gravl.io" :
    process.env.REACT_APP_ENV === "PROD" ? "https://admin.gravl.io" :
    process.env.REACT_APP_ENV === "DEMO" ? "https://admin-demo.gravl.io" :
    "https://admin.gravl.io"

    const token = localStorage.getItem("gravl_token");

    setDomianCookie("gravl_token", token, 5);

    window.open(redirectLink, "_blank");
  }

  return (
    <div className="header">
      <div onClick={handleClick} className="header__logo-link">
        <img src={logo} alt="logo" className="header__logo" />
      </div>
      {isLoading ? (
        <div className="loading">
          <Spin size="large">
            <div className="content" />
          </Spin>
          <p className="loading__text">Loading</p>
        </div>
      ) : (
        <div className="header__wrapp">
          {!token && (
            <div
              className="header__signup-divider"
              onClick={handleCartNavigate}
            >
              <ShoppingCart className="shop-icon" size={24} />
              {cartCtx.items.length > 0 && (
                <div className="header__signup-order">
                  <Dot className="dot-icon" size={24} />
                </div>
              )}
            </div>
          )}
          {successfulSignUp === false && (
            <>
              {!token && (
                <div className="header__signup">
                  <ButtonGradient
                    text="Sign Up"
                    icon={<UserRoundPlus className="user-icon" size={20} />}
                    handleClick={handleOpenModal}
                  />
                </div>
              )}
              <div className="checkout__btnWrapp-divider" />
            </>
          )}

          <div className="header__signup">
            {!token && (
              <ButtonWhite text="Sign In" handleClick={handleOpenLogin} />
            )}
          </div>
          {token && (
            <div className="header__signup-section">
              <div className="header__signup-userIn">
                <div
                  className="header__signup-divider"
                  onClick={handleCartNavigate}
                >
                  <ShoppingCart className="shop-icon" size={24} />
                  {cartCtx.items.length > 0 && (
                    <div className="header__signup-order">
                      <Dot className="dot-icon" size={24} />
                    </div>
                  )}
                </div>

                <a
                  onClick={handleRedirect}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="header__signup-avatar"
                >
                  {initials}
                </a>
                <div className="header__signup-userName">
                  {userName} {userLastName}
                </div>
                <div onClick={handleLogOut} className="header__signup-out">
               Log Out
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
