import React from "react";
import { useParams, useRouteError } from "react-router-dom";
import ReactGA from 'react-ga4';


const ErrorPage = () => {
  const { uniId: id } = useParams();
  let error = useRouteError();

  ReactGA.send({
    hitType: "pageview",
    page: "/error",
    title: "Error: " + error.statusText
  })
 
  return (
    <div>
      <h1>Uh oh, something went terribly wrong 😩</h1>
      <pre>{error.message || JSON.stringify(error.statusText)}</pre>
      <pre>{error.message || JSON.stringify(error.data)}</pre>
      <button onClick={() => (window.location.href = `/university/${id || 1}`)}>
        Click here to reload the app
      </button>
    </div>
  );
};

export default ErrorPage;
