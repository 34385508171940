import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import CartContext from "./context/cartContext";
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { BACKEND_URL } from "./utils/serverData";
import { universityInfoContext } from "./context/universityInfoContext";

const Root = () => {
  const cartCtx = useContext(CartContext);
  const { setUniversityInfo, setUniversityCurrency } = useContext(
    universityInfoContext
  );

  const user_email = localStorage.getItem("user_email");
  const gravl_token = localStorage.getItem("gravl_token");

  const { uniId: id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      let uniId = id;

      try {
        const universityResponse = await axios.get(
          `${BACKEND_URL}/client/university/${uniId}`
        );

        setUniversityInfo(universityResponse.data);
        setUniversityCurrency(universityResponse.data.currency);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (setUniversityInfo?.id !== id || setUniversityInfo?.nameId !== id) {
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (!id && location.pathname !== "/terms-and-conditions" && location.pathname !== "/reset-password") {
      navigate(`/university/${id || 1}`);
    }
  }, [id, navigate]);

  useEffect(() => {
    if (gravl_token) {
      //update cart with nez
      const timeout = setTimeout(() => {
        axios
          .post(
            `${BACKEND_URL}/client/cart`,
            {
              cart: cartCtx.items
                .map((item) => {
                  // Find the index of the selected pricing option
                  const selectedIndex = item.options.data.pricing.findIndex(
                    (p) => p.selected === true
                  );

                  // Ensure selectedIndex is not -1

                  if (selectedIndex < 0) {
                    console.log("cannot find index", item.options.data.pricing);

                    return [];
                  }

                  return {
                    optionId: item.options.id,
                    optionIndex: selectedIndex, // Ensure it's a non-negative index
                    amount: item.quantity,
                  };
                })
                .flat(),
            },
            {
              headers: {
                Authorization: `Bearer ${gravl_token}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);

      return () => clearTimeout(timeout);
    }
  }, [cartCtx, gravl_token, user_email]);

  return (
    <>
      {location.pathname !== "/terms-and-conditions" && (<Header />)}
      {/* <Header /> */}
      <Outlet />
      {location.pathname !== "/terms-and-conditions" && (<Footer />)}
      {/* <Footer /> */}
    </>
  );
};

export default Root;
