import React, { useContext, useEffect, useState } from "react";
import { Checkbox, Col, InputNumber, Row, Slider } from "antd";
import { ChevronDown, ChevronRight } from "lucide-react";
import { filterInstrumentsContext } from "../../../../context/filterInstrumentsContext";

const ReadLength = ({
  handleCheckboxChangeReadLength,
  handleVisibleReadLength,
  onChangeReadLengthSlider,
}) => {
  const {
    filterData,
    inputLength,
    checkReadLength,
    visibleReadLength,
    setInputLength,
  } = useContext(filterInstrumentsContext);

  const [minInput, setMinInput] = useState(inputLength?.min ?? filterData.readLengthRange?.min);
  const [maxInput, setMaxInput] = useState(inputLength?.max ?? filterData.readLengthRange?.max);

  let minTimeout, maxTimeout; 

  useEffect(() => {
    const timeout = setTimeout(() => {
    setMinInput(inputLength?.min ?? filterData.readLengthRange?.min);
    setMaxInput(inputLength?.max ?? filterData.readLengthRange?.max);
  }, 1000);

  return () => clearTimeout(timeout);

  }, [inputLength, filterData.readLengthRange]);

  const findClosestMark = (value) => {
    const marks = filterData.readLengthRange.marks;
    let closest = marks[0];
    let smallestDifference = Math.abs(value - closest);

    marks.forEach((mark) => {
      const difference = Math.abs(value - mark);
      if (difference < smallestDifference) {
        closest = mark;
        smallestDifference = difference;
      }
    });

    return closest;
  };

  const handleMinInputChange = (value) => {
    setMinInput(value);
    clearTimeout(minTimeout); 
    minTimeout = setTimeout(() => {
      const closestMark = findClosestMark(value);
      setInputLength((prev) => ({
        ...prev,
        min: closestMark,
      }));
    }, 500); 
  };

  const handleMaxInputChange = (value) => {
    setMaxInput(value);
    clearTimeout(maxTimeout); 
    maxTimeout = setTimeout(() => {
      const closestMark = findClosestMark(value);
      setInputLength((prev) => ({
        ...prev,
        max: closestMark,
      }));
    }, 500); 
  };




  const originalMarks = filterData.readLengthRange.marks;
  const transformedMarks = {};

  originalMarks.forEach((mark) => (transformedMarks[mark] = " "));

  const marks = {
    [filterData?.readLengthRange?.min]: filterData?.readLengthRange?.min,
    ...transformedMarks,
    [filterData?.readLengthRange?.max]: filterData?.readLengthRange?.max,
  };
  return (
    <div className="filter-sequencing__readLengthBox">
      <div className="filter-sequencing__readLength">
        <Checkbox
          onChange={handleCheckboxChangeReadLength}
          checked={checkReadLength}
        >
          <div className="filter-sequencing__boxTitle">Read Length (bp)</div>
        </Checkbox>
        <div
          onClick={handleVisibleReadLength}
          className="filter-sequencing__iconRead"
        >
          {checkReadLength ? (
            visibleReadLength ? (
              <ChevronDown className="filter-sequencing__icon-arrow" />
            ) : (
              <ChevronRight className="filter-sequencing__icon-arrow" />
            )
          ) : null}
        </div>
      </div>

      {visibleReadLength && (
        <Col className="filter-sequencing__readLengthBox--visibleRange">
          <Col span={24}>
            {checkReadLength && (
              <Slider
                range
                marks={marks}
                step={
                  filterData?.readLengthRange?.max -
                  filterData?.readLengthRange?.min
                }
                min={filterData?.readLengthRange?.min}
                max={filterData?.readLengthRange?.max}
                onChange={onChangeReadLengthSlider}
                value={
                  inputLength
                    ? [inputLength?.min, inputLength?.max]
                    : [
                        filterData.readLengthRange.min,
                        filterData.readLengthRange.max,
                      ]
                }
             
              />
            )}
          </Col>
          {checkReadLength && (
            <Row className="filter-sequencing__range">
              <InputNumber
                value={minInput}
                onChange={(value) => handleMinInputChange(value)}
           
              />
              <InputNumber
                value={maxInput}
                onChange={(value) => handleMaxInputChange(value)}
               
              />
            </Row>
          )}
        </Col>
      )}
    </div>
  );
};

export default ReadLength;
