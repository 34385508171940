import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import { BACKEND_URL } from "../../utils/serverData";
import "./Team.scss";
import TeamCard from "./teamCard/TeamCard";

const Team = () => {
  const { facilityId: facId } = useParams();
  const [teamData, setTeamData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const fetchUniInfo = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/client/facilities/${facId}/team`
        );

        setTeamData(response.data.facility.team);
        // console.log(response.data)
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching universities data:", error);
      }
    };

    fetchUniInfo();
  }, [facId]);

  return (
    <div className="team">
      {isLoading
        ?    Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className="team-box">
            <Skeleton variant="circular" width={150} height={150} />
            <Skeleton variant="rectangular" width={210} height={80} />
            <Skeleton variant="rectangular" width={210} height={200} />
          </div>
        ))
        : teamData.map((person, index) => (
            <TeamCard key={index} person={person} />
          ))}
    </div>
  );
};

export default Team;
