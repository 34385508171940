import React from "react";
import { Clock, Hourglass, Rows4, Users } from "lucide-react";
import Skeleton from "@mui/material/Skeleton";
import "./FunFacts.scss";

const FunFacts = ({ aboutData, isLoading }) => {
  return (
    <>
      {aboutData ? (
        <div className="funfacts">
          <h2 className="funfacts__title">Scientific Impact</h2>

          <div className="funfacts__lables">
            {aboutData &&
              Object?.entries(aboutData)?.map(([label, value], index) => (
                <div className="funfacts__lables-item" key={index}>
                  <div className="funfacts__lables-item quantity">
                    {/* Determine which icon to display based on the label */}
                    {label === "Publications last year" && (
                      <Rows4
                        color="#36C6E6"
                        size={24}
                        className="funfacts-icon"
                      />
                    )}
                    {(label === "Genomes last year alone" || label === "Samples analysed") && (
                      <Hourglass
                        color="#36C6E6"
                        size={24}
                        className="funfacts-icon"
                      />
                    )}
                    {label === "Lane working time" && (
                      <Clock
                        color="#36C6E6"
                        size={24}
                        className="funfacts-icon"
                      />
                    )}
                    {label === "Clients" && (
                      <Users
                        color="#36C6E6"
                        size={24}
                        className="funfacts-icon"
                      />
                    )}
                    {value}
                  </div>
                  <div className="funfacts__lables-item publish">{label}</div>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <Skeleton variant="rectangular" height={200} />
      )}
    </>
  );
};

export default FunFacts;
