// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset {
  margin: 0 auto;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }
  .reset .sign-modal__form {
    align-items: center; }
  .reset .sign-modal__input {
    width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/ResetPass/ResetPass.scss"],"names":[],"mappings":"AACA;EACI,cAAc;EACd,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW,EAAA;EANf;IAQQ,mBAAmB,EAAA;EAR3B;IAYQ,WAAW,EAAA","sourcesContent":["\n.reset{\n    margin: 0 auto;\n    height: 70vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    .sign-modal__form{\n        align-items: center;\n       \n    }\n    .sign-modal__input{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
