import React from "react";
import ImageGallery from "react-image-gallery";
import "./Gallery.scss";

const Gallery = ({aboutData}) => {

  if (!Array.isArray(aboutData)) {
    return null; // If aboutData is not an array, return null
  }

  // Map each URL in aboutData to the format expected by ImageGallery
  const images = aboutData.map((url, index) => ({
    original: url,
    thumbnail: url,
    // Optionally, you can include an 'id' for each image if needed
    id: index.toString(),
  }));

  return (
    <div className="gallery">
      <ImageGallery items={images} additionalClass="image" />
    </div>
  );
};

export default Gallery;
