import React, { useContext } from "react";
import RadioSelection from "../ui/radioSelections/RadioSelection";
import "./OptionTypeCard.scss";
import { formatPrice, formatReads } from "../../utils/currenceFormatter";
import { universityInfoContext } from "../../context/universityInfoContext";
const OptionTypeCard = ({
  props,
  indexType,
  handleIndexChange,
  setSelectedReadType,
  setSelectedPrice,
  setSelectedOutput,
  setSelectedAmount,
}) => {
  const { universityCurrency } = useContext(universityInfoContext);

  return (
    <form className="form">
      {props?.options?.data?.pricing.map((option, index) => (
        <li key={index} className="run-item-list">
          <RadioSelection
            name="indexType"
            value={option.id}
            checked={indexType === option.id || indexType.id === option.id}
            onChange={() => {
              handleIndexChange(option.id);
              // setSelectedReadType(option.data.readType || option.data.coverage);
              // setSelectedPrice(option.price);
              // setSelectedOutput(option.data.output);
              // setSelectedAmount(option.data.amount);
            }}
            label={
              <div className="run-item-wrapp">
                <div className="run-item-label">
                  {[
                    option.data.readType && option.data.output
                      ? `${option.data.readType}, ${option.data.output} Gb`
                      : null,
                    option.data.coverage && option.data.output
                      ? `${option.data.coverage}=${option.data.output} Gb`
                      : null,
                    option.data.reads
                      ? `${formatReads(option.data.reads)} reads`
                      : null,

                    option.data.amount ? option.data.amount : null,
                    option.data.name ? option.data.name : null,
                  ]
                    .filter(Boolean) // Remove any null or undefined items
                    .join(", ")}
                </div>
                <span>{formatPrice(option.price, universityCurrency)}</span>
              </div>
            }
            id={`radio-${index}`}
          />
        </li>
      ))}
    </form>
  );
};

export default OptionTypeCard;
