import React, { useContext } from "react";

import "./FilterSequencing.scss";

import AllWorkFlow from "../subFilters/AllWorkFlow";
import Sequencing from "../subFilters/Sequencing";
import LibraryPreparation from "../subFilters/LibraryPreparation";
import SampleHand from "../subFilters/SampleHand";
import BiomarkerPanels from "../subFilters/BiomarkerPanels";
import DataAnalysis from "../subFilters/DataAnalysis";
import { filterInstrumentsContext } from "../../../context/filterInstrumentsContext";

const FilterSequencing = () => {
  //start checkbox all-work-flow
  const {
    filterData,
    checkedList,
    setCheckedList,
    checkedListLabs,
    setCheckedListLab,
    checkedListAnalys,
    setCheckedListAnalys,
    visibleAllWorkFlow,
    setVisibleAllWorkFlow,
    visibleSeq,
    setVisibleSeq,
    visibleLabs,
    setVisibleLab,
    visibleSample,
    setVisibleSample,
    visibleAnalys,
    setVisibleAnalys,
    checkedSeqList,
    checkedSampleList,
    setCheckedSampleList,
    checkedBiomarkerPanelsList,
    setCheckedBiomarkerPanelsList,
    visibleBiomarkerPanels,
    setVisibleBiomarkerPanels,
  } = useContext(filterInstrumentsContext);

  const handleVisibleChange = () => {
    setVisibleAllWorkFlow(!visibleAllWorkFlow);
  };

  const plainOptions = filterData?.serviceTypes?.AllInOne;

  const sequencersOptions = filterData?.serviceTypes?.Sequencing;

  const checkAll = plainOptions?.length === checkedList?.length;
  const indeterminate =
    checkedList?.length > 0 && checkedList?.length < plainOptions?.length;

  const onChange = (list) => {
    setCheckedList(list);
  };

  const onCheckAllChange = (e) => {
    const checked = e.target.checked;
    setCheckedList(checked ? plainOptions : []);
  };

  //finish checkbox all-work-flow

  // start checkbox sequencing

  const handleVisibleSeq = () => {
    setVisibleSeq(!visibleSeq);
  };

  const seqOptions = filterData?.serviceTypes?.Sequencing;

  const sequencings =
    checkedSeqList?.length > 0 && checkedSeqList?.length < seqOptions?.length;

  //finish checkbox sequencing

  // start checkbox library preparation

  const handleVisibleLabs = () => {
    setVisibleLab(!visibleLabs);
  };

  const labOptions = filterData?.serviceTypes?.LibraryPreparation;

  const checkAllLibraries = labOptions?.length === checkedListLabs?.length;
  const labLength =
    checkedListLabs?.length > 0 && checkedListLabs?.length < labOptions?.length;

  const onChangeLab = (list) => {
    setCheckedListLab(list);
  };

  const onCheckAllLabs = (e) => {
    setCheckedListLab(e.target.checked ? labOptions : []);
  };

  //finish checkbox library preparation

  // start checkbox qa sample handling

  const handleVisibleSample = () => {
    setVisibleSample(!visibleSample);
  };

  const sampleOptions =
    filterData?.serviceTypes?.QualityControlAndSampleHandling;
  const checkAllSamples = sampleOptions?.length === checkedSampleList?.length;
  const sampleLength =
    checkedSampleList?.length > 0 &&
    checkedSampleList?.length < sampleOptions?.length;

  const onChangeSample = (list) => {
    setCheckedSampleList(list);
  };

  const onCheckAllChangeSample = (e) => {
    setCheckedSampleList(e.target.checked ? sampleOptions : []);
  };

  //finish qa sample handling

  // start checkbox Biomarker Panels

  const handleVisibleBiomarkerPanels = () => {
    setVisibleBiomarkerPanels(!visibleBiomarkerPanels);
  };

  const biomarkerPanelsOptions = filterData?.serviceTypes?.BiomarkerPanels;
  const checkAllBiomarkerPanels =
    biomarkerPanelsOptions?.length === checkedBiomarkerPanelsList?.length;
  const biomarkerPanelsLength =
    checkedBiomarkerPanelsList?.length > 0 &&
    checkedBiomarkerPanelsList?.length < biomarkerPanelsOptions?.length;

  const onChangeBiomarkerPanels = (list) => {
    setCheckedBiomarkerPanelsList(list);
  };

  const onCheckAllChangeBiomarkerPanels = (e) => {
    setCheckedBiomarkerPanelsList(
      e.target.checked ? biomarkerPanelsOptions : []
    );
  };

  //finish Biomarker Panels

  const handleVisibleChangeAnalys = () => {
    setVisibleAnalys(!visibleAnalys);
  };

  const analysOptions = filterData?.serviceTypes?.DataAnalysis;
  const checkAllAnalys = analysOptions?.length === checkedListAnalys?.length;
  const analysLength =
    checkedListAnalys?.length > 0 &&
    checkedListAnalys?.length < analysOptions?.length;

  const onChangeAnalys = (list) => {
    setCheckedListAnalys(list);
  };

  const onCheckAllChangeAnalys = (e) => {
    setCheckedListAnalys(e.target.checked ? analysOptions : []);
  };

  return (
    <div className="filter-sequencing">
      <div className="filter-sequencing__divider" />
      {plainOptions && (
        <>
          <AllWorkFlow
            onChange={onChange}
            onCheckAllChange={onCheckAllChange}
            indeterminate={indeterminate}
            checkAll={checkAll}
            visible={visibleAllWorkFlow}
            handleVisibleChange={handleVisibleChange}
            plainOptions={plainOptions}
            checkedList={checkedList}
          />
          <div className="filter-sequencing__divider" />
        </>
      )}
      {sampleOptions?.length > 0 && (
        <>
          <SampleHand
            sampleOptions={sampleOptions}
            visibleSample={visibleSample}
            handleVisibleSample={handleVisibleSample}
            checkAllSamples={checkAllSamples}
            sampleLength={sampleLength}
            onCheckAllChangeSample={onCheckAllChangeSample}
            checkedSampleList={checkedSampleList}
            onChangeSample={onChangeSample}
          />
          <div className="filter-sequencing__divider" />
        </>
      )}
      {labOptions?.length > 0 && (
        <>
          <LibraryPreparation
            labLength={labLength}
            onCheckAllLabs={onCheckAllLabs}
            checkAllLibraries={checkAllLibraries}
            handleVisibleLabs={handleVisibleLabs}
            visibleLabs={visibleLabs}
            labOptions={labOptions}
            checkedListLabs={checkedListLabs}
            onChangeLab={onChangeLab}
          />
          <div className="filter-sequencing__divider" />
        </>
      )}
      {biomarkerPanelsOptions?.length > 0 && (
        <>
          <BiomarkerPanels
            biomarkerPanelsOptions={biomarkerPanelsOptions}
            visibleBiomarkerPanels={visibleBiomarkerPanels}
            handleVisibleBiomarkerPanels={handleVisibleBiomarkerPanels}
            checkAllBiomarkerPanelss={checkAllBiomarkerPanels}
            biomarkerPanelsLength={biomarkerPanelsLength}
            onCheckAllChangeBiomarkerPanels={onCheckAllChangeBiomarkerPanels}
            checkedBiomarkerPanelsList={checkedBiomarkerPanelsList}
            onChangeBiomarkerPanels={onChangeBiomarkerPanels}
          />
          <div className="filter-sequencing__divider" />
        </>
      )}
      {sequencersOptions?.length > 0 && (
        <>
          <Sequencing
            visibleSeq={visibleSeq}
            handleVisibleSeq={handleVisibleSeq}
            sequencings={sequencings}
          />
          <div className="filter-sequencing__divider" />
        </>
      )}
      {analysOptions?.length > 0 && (
        <>
          <DataAnalysis
            analysLength={analysLength}
            onCheckAllChangeAnalys={onCheckAllChangeAnalys}
            checkAllAnalys={checkAllAnalys}
            handleVisibleChangeAnalys={handleVisibleChangeAnalys}
            visibleAnalys={visibleAnalys}
            analysOptions={analysOptions}
            checkedListAnalys={checkedListAnalys}
            onChangeAnalys={onChangeAnalys}
          />
        </>
      )}
    </div>
  );
};

export default FilterSequencing;
