import React from "react";
import { Collapse } from "antd";
import { Search } from "lucide-react";
import "./FilterInstruments.scss";
import FilterSequencing from "./filtersTypes/FilterSequencing";

import PriceRange from "./filtersTypes/priceRange/PriceRange";

const FilterInstruments = ({ handleSendFilters, handleCleanFilters }) => {
  let items = [];
  items = [
    {
      key: "2",
      label: "Service Types",
      children: <FilterSequencing />,
    },
    // {
    //   key: "3",
    //   label: "Availability",
    //   children: <FilterAvailability />,
    // },

    {
      key: "6",
      label: "Price Range",
      children: <PriceRange />,
    },
  ];

  const defaultActiveKey = items.some((item) => item.key === "1")
    ? ["1"]
    : ["2"];
  return (
    <div className="filter-instruments">
      <Collapse
        defaultActiveKey={defaultActiveKey}
        ghost
        expandIconPosition="end"
        items={items}
      />
      <div className="filter-instruments__buttons">
        <div
          className="filter-instruments__generateQuote"
          onClick={handleCleanFilters}
        >
          CLEAR
        </div>
        <div className="filter-instruments__button" onClick={handleSendFilters}>
          <Search className="filter-instruments__icon" color="#fff" />
          Search
        </div>
      </div>
    </div>
  );
};

export default FilterInstruments;
