import React from "react";
import "./Specifications.scss";

const Specifications = ({
  description,
  platform,
  flowtype,
  flowNum,
  effect,
  lane,
  temperature,
  humidity,
  pressure,
}) => {
  return (
    <div className="specifications">
      <div className="specifications-info">
        <h2 className="specifications-title">Specifications</h2>
        <div className="specifications-description__table">
          <div className="specifications-description__table name">Platform</div>
          <div className="specifications-description__table value">
            {platform}
          </div>
        </div>
        <div className="specifications-description__table">
          <div className="specifications-description__table name">
            Flow Cell
          </div>
          <div className="specifications-description__table value">
            {flowNum}
          </div>
        </div>
        <div className="specifications-description__table">
          <div className="specifications-description__table name">
            Flow Cell Type
          </div>
          <div className="specifications-description__table value">
            {flowtype}
          </div>
        </div>
        <div className="specifications-description__table">
          <div className="specifications-description__table name">
            Lane No./Flow Cell
          </div>
          <div className="specifications-description__table value">{lane}</div>
        </div>
        <div className="specifications-description__table">
          <div className="specifications-description__table name">
            Effective Reads
          </div>
          <div className="specifications-description__table value">
            {effect}
          </div>
        </div>
        <div className="specifications-description__table">
          <div className="specifications-description__table name">
            Temperature
          </div>
          <div className="specifications-description__table value">{temperature}</div>
        </div>
        <div className="specifications-description__table">
          <div className="specifications-description__table name">
            Relative Humidity
          </div>
          <div className="specifications-description__table value">
            {humidity}
          </div>
        </div>
        <div className="specifications-description__table">
          <div className="specifications-description__table name">
            Atmospheric Pressure
          </div>
          <div className="specifications-description__table value">
            {pressure}
          </div>
        </div>
      </div>
      <div className="specifications-description">
        <h2 className="specifications-title">Description</h2>
        <p className="specifications-description__text">{description}</p>
      </div>
    </div>
  );
};

export default Specifications;
