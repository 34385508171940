export const currenceFormatter = new Intl.NumberFormat("en-Us", {
  style: "currency",
  currency: "USD",
});

export function formatPrice(price, currency = "USD") {
  currency = currency ? currency : "USD";

  return new Intl.NumberFormat(currency === "USD" ? "en-US" : "es-ES", {
    style: "currency",
    currency: currency,
  }).format(price);
}

export function formatPriceRangeFilter(price, currency = "USD") {
  currency = currency ? currency : "USD";

  return `${new Intl.NumberFormat(
    currency === "USD" ? "en-US" : "es-ES"
  ).format(price)}`
}

function removeTrailingZeroes(num) {
  // Якщо число закінчується на ".0", видаляємо ".0"
  return num.toString().replace(/\.0$/, "");
}
export function formatReads(reads) {
  if (reads >= 1000) {
    const billions = (reads / 1000).toFixed(1);
    return `${removeTrailingZeroes(billions)}B`;
  } else if (reads >= 1) {
    const millions = reads.toFixed(1);
    return `${removeTrailingZeroes(millions)}M`;
  } else {
    const thousands = (reads * 1000).toFixed(1);
    return `${thousands}K`;
  }
}
