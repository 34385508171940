import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TermsAndConditions.scss";
import { BACKEND_URL } from "../../utils/serverData";


const TermsAndConditions = () => {
  const [documentContent, setDocumentContent] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/client/terms`
        );

        setDocumentContent(response.data.content)

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="termsAndConditions">
      <div
        dangerouslySetInnerHTML={{ __html: documentContent }}
      />
    </div>
  );
};

export default TermsAndConditions;
