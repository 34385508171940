export const parseReadyIn = (days) => {
    const weeks = Math.floor(days / 7);
    const remainingDays = days % 7;
  
    let result = "";
    if (weeks > 0) {
      result += `${weeks} week${weeks > 1 ? "s" : ""}`;
      if (remainingDays > 0) {
        result += ` and `;
      }
    }
    if (remainingDays > 0) {
      result += `${remainingDays} day${remainingDays > 1 ? "s" : ""}`;
    }
  
    return result;
  };

  export const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];