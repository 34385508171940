import { createContext, useReducer } from "react";

const CartContext = createContext({
  items: [],
  addItem: (item, selectedIndex, amount) => {},
  clearCart: () => {},
  changeIndex: (itemIndex, selectedIndex) => {},
  changeAmount: (itemIndex, amount) => {},
});

function CartReducer(state, action) {
  if (action.type === "CHANGE_INDEX") {
    const existingCartItemIndex = action.itemIndex;
    const newOptionIndex = action.selectedIndex;

    const updatedItems = [...structuredClone(state.items)];

    const existingItem = state.items[existingCartItemIndex];

    if (
      newOptionIndex ===
      existingItem.options.data.pricing.findIndex((e) => e.selected)
    ) {
      return state;
    }

    //check if item with this optin exists
    if (
      updatedItems
        .filter(
          (updatedItem) => updatedItem.service.id === existingItem.service.id
        )
        .findIndex((updatedItem) => {
          return (
            updatedItem.options.data.pricing.find((i) => i.selected).id ===
            action.selectedIndex
          );
        }) > -1
    ) {
      const sameOptionItem = updatedItems.find((updatedItem) => {
      
        return (
          updatedItem.options.data.pricing.find((i) => i.selected).id ===
          action.selectedIndex
        );
      });

      sameOptionItem.quantity = sameOptionItem?.quantity + existingItem?.quantity;


      updatedItems.splice(existingCartItemIndex, 1);

      return { ...state, items: updatedItems };
    } else {
      existingItem.options.data.pricing = existingItem.options.data.pricing.map(
        (pricing, index) =>
          pricing.id === newOptionIndex
            ? { ...pricing, selected: true }
            : { ...pricing, selected: false }
      );

      const updatedItem = {
        ...structuredClone(existingItem),
      };

      updatedItems[existingCartItemIndex] = updatedItem;

      return { ...state, items: updatedItems };
    }
  }

  if (action.type === "CHANGE_AMOUNT") {
    console.log("change amount");
    const existingCartItemIndex = action.itemIndex;
    const amountToAdd = action.amount;

    const updatedItems = [...structuredClone(state.items)];

    const existingItem = state.items[existingCartItemIndex];

    console.log(amountToAdd);
    existingItem.quantity = existingItem.quantity + amountToAdd;

    if (existingItem.quantity <= 0) {
      updatedItems.splice(existingCartItemIndex, 1);
    } else {
      updatedItems[existingCartItemIndex] = existingItem;
    }

    return { ...state, items: updatedItems };
  }

  if (action.type === "ADD_ITEM") {
    const updatedItems = [...structuredClone(state.items)];

    //check if this item with this option selected exists
    if (
      updatedItems
        .filter(
          (updatedItem) => updatedItem.service.id === action.item.service.id
        )
        .findIndex((updatedItem) => {
          return (
            updatedItem.options.data.pricing.findIndex((e) => e.selected) ===
            action.selectedIndex
          );
        }) > -1
    ) {
      const existingEl = updatedItems.find((updatedItem) => {
    
        return (
          updatedItem.options.data.pricing.findIndex((e) => e.selected) ===
            action.selectedIndex &&
          updatedItem.service.id === action.item.service.id
        );
      });

      existingEl.quantity = existingEl.quantity + action.amount;

      return { ...state, items: updatedItems };
    } else {
      const newItem = { ...structuredClone(action.item), quantity: action.amount };

      console.log(action.selectedIndex);

      newItem.options.data.pricing = newItem.options.data.pricing.map(
        (pricing, index) =>
          pricing.id === action.selectedIndex
            ? { ...pricing, selected: true }
            : { ...pricing, selected: false }
      );

      updatedItems.push(newItem);

      return { ...state, items: updatedItems };
    }
  }

  if (action.type === "REMOVE_ITEM") {
    const updatedItems = [...structuredClone(state.items)];
    const index = action.itemIndex;

    //check if this item with this option selected exists
    if (state.items[index]) {
      updatedItems.splice(index, 1);

      return { ...state, items: updatedItems };
    }

    return state;
  }

  if (action.type === "CLEAR_CART") {
    return { ...state, items: [] };
  }
  return state;
}

export function CartContextProvider({ children }) {
  const [cart, dispatchCartAction] = useReducer(CartReducer, { items: [] });

  function addItem(item, selectedIndex, amount) {
    dispatchCartAction({ type: "ADD_ITEM", item, selectedIndex, amount});
  }

  function changeIndex(itemIndex, selectedIndex) {
    dispatchCartAction({ type: "CHANGE_INDEX", itemIndex, selectedIndex });
  }

  function changeAmount(itemIndex, amount) {
    dispatchCartAction({ type: "CHANGE_AMOUNT", itemIndex, amount });
  }
  function removeItem(itemIndex) {
    dispatchCartAction({ type: "REMOVE_ITEM", itemIndex });
  }
  function clearCart() {
    dispatchCartAction({ type: "CLEAR_CART" });
  }
  const cartContext = {
    items: cart.items,
    addItem,
    clearCart,
    changeIndex,
    changeAmount,
    removeItem,
  };
 console.log(cart);
  return (
    <CartContext.Provider value={cartContext}>{children}</CartContext.Provider>
  );
}
export default CartContext;
