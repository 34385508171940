export const constructQueryParams = (
  filterData,
  priceRange,
  checkedList,
  checkedSeqList,
  checkedListLabs,
  checkedSampleList,
  checkedBiomarkerPanelsList,
  checkedListAnalys,
  checkedInstrumentType,
  laneChecked,
  cellChecked,
  inputValueReadLength,
  inputValueOutput,
  availbDate,
  indexTypeAvailbDate,
  checkedListRead,
  checkRange,
  checkReadLength,
  inputLength,
  checkOutput
) => {
  const queryParams = [];

  // Price range parameters
  if (priceRange) {
    const { min, max } = priceRange;
    if (min && max) {
      if (min) queryParams.push(`pr.min=${min}`);
      if (max) queryParams.push(`pr.max=${max}`);
    }
  } else {
    const { min, max } = filterData.priceRange;
    if (min && max) {
      if (min) queryParams.push(`pr.min=${min}`);
      if (max) queryParams.push(`pr.max=${max}`);
    }
  }

  // Service types parameters
  const checkedListQueryParam =
    checkedList?.length > 0 ? checkedList.join(",") : null;
  const checkedSeqListQueryParam =
    checkedInstrumentType?.length > 0 ? checkedInstrumentType.join(",") : null;
  const checkLabParam =
    checkedListLabs?.length > 0 ? checkedListLabs.join(",") : null;
  const checkSampleParam =
    checkedSampleList?.length > 0 ? checkedSampleList.join(",") : null;
  const checkBiomarkerPanelsParam =
    checkedBiomarkerPanelsList?.length > 0 ? checkedBiomarkerPanelsList.join(",") : null;
  const checkDataAnalys =
    checkedListAnalys?.length > 0 ? checkedListAnalys.join(",") : null;

  if (checkedListQueryParam)
    queryParams.push(`st.AllInOne=${checkedListQueryParam}`);
  if (
    checkedSeqListQueryParam ||
    laneChecked ||
    cellChecked ||
    // indexTypeAvailbDate ||
    checkedListRead ||
    checkOutput ||
    checkRange ||
    checkReadLength
  )
    queryParams.push(`st.Sequencing=`);
  if (checkLabParam) queryParams.push(`st.LibraryPreparation=${checkLabParam}`);
  if (checkSampleParam)
    queryParams.push(`st.QualityControlAndSampleHandling=${checkSampleParam}`);
  if (checkBiomarkerPanelsParam)
    queryParams.push(`st.BiomarkerPanels=${checkBiomarkerPanelsParam}`);
  if (checkDataAnalys) queryParams.push(`st.DataAnalysis=${checkDataAnalys}`);

  // Instrument types parameters

  checkedInstrumentType?.forEach((formatted) => {
    const [subtitle, models] = formatted.split(": ");
    const decodedModels = decodeURIComponent(models); // Decode URL encoding
    const formattedModels = decodedModels
      ? `it.${subtitle}=`
      : `it.${subtitle}=`;
    queryParams.push(formattedModels);
  });

  if (
    laneChecked !== null &&
    laneChecked !== undefined &&
    laneChecked !== false
  ) {
    queryParams.push(`lane=${laneChecked ? 1 : 0}`);
  }
  if (
    cellChecked !== null &&
    cellChecked !== undefined &&
    cellChecked !== false
  ) {
    queryParams.push(`flowCell=${cellChecked ? 1 : 0}`);
  }

  if (inputValueReadLength && checkRange) {
    const { min, max } = inputValueReadLength;
    if (min && max) {
      if (min) queryParams.push(`ra.min=${min}`);
      if (max) queryParams.push(`ra.max=${max}`);
    }
  }

  if (inputLength && checkReadLength) {
    const { min, max } = inputLength;
    if (min && max) {
      if (min) queryParams.push(`rl.min=${min}`);
      if (max) queryParams.push(`rl.max=${max}`);
    }
  }

  if (inputValueOutput && checkOutput) {
    const { min, max } = inputValueOutput;
    if (min && max) {
      if (min) queryParams.push(`o.min=${min}`);
      if (max) queryParams.push(`o.max=${max}`);
    }
  }

  if (
    indexTypeAvailbDate !== "" &&
    indexTypeAvailbDate !== undefined &&
    availbDate.min !== "" &&
    availbDate.max !== ""
  ) {
    queryParams.push(`ar.min=${availbDate.min}`);
    queryParams.push(`ar.max=${availbDate.max}`);
  }
  if (
    checkedListRead !== "" &&
    checkedListRead !== null &&
    checkedListRead !== undefined
  ) {
    queryParams.push(`readType=${checkedListRead}`);
  }
  return queryParams.join("&");
};
