import React from "react";
import { LayoutGrid, LayoutList } from "lucide-react";
import "./ViewSelector.scss";


const ViewSelector = ({ handleViewChange, view, title }) => {
   
  return (
    <div className="view-selector">
      <h2 className="view-selector__title">{title}</h2>
      <div className="view-selector__btn">
        <LayoutGrid
          onClick={() => handleViewChange("card")}
          style={{ color: view === "list" ? "rgba(54, 198, 230, 0.2)" : "rgba(54, 198, 230, 1)" }}
       />
          
        <LayoutList
          onClick={() => handleViewChange("list")}
          style={{ color: view === "card" ? "rgba(54, 198, 230, 0.2)" : "rgba(54, 198, 230, 1)" }}
        />
       
      </div>
    </div>
  );
};

export default ViewSelector;
