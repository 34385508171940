import React from "react";
import { Checkbox } from "antd";
import { ChevronDown, ChevronRight, Info } from "lucide-react";
const CheckboxGroup = Checkbox.Group;
const BiomarkerPanels = ({
  biomarkerPanelsLength,
  onCheckAllChangeBiomarkerPanels,
  checkAllBiomarkerPanelss,
  handleVisibleBiomarkerPanels,
  visibleBiomarkerPanels,
  biomarkerPanelsOptions,
  checkedBiomarkerPanelsList,
  onChangeBiomarkerPanels,
}) => {
  return (
    <>
      <div className="filter-sequencing__boxes">
        <Checkbox
          indeterminate={biomarkerPanelsLength}
          onChange={onCheckAllChangeBiomarkerPanels}
          checked={checkAllBiomarkerPanelss}
        >
          <div className="filter-sequencing__boxTitle">
          Biomarker Panels
          </div>
          <Info className="filter-sequencing__icon-info" size={16} />
        </Checkbox>
        <div onClick={handleVisibleBiomarkerPanels} className="filter-sequencing__iconSample">
        {!visibleBiomarkerPanels && (<ChevronRight className="filter-sequencing__icon-arrow" /> )}
        {visibleBiomarkerPanels && (<ChevronDown className="filter-sequencing__icon-arrow" /> )}
        </div>
      </div>
      <div className="filter-sequencing__groups">
        {visibleBiomarkerPanels && (
          <CheckboxGroup
            options={biomarkerPanelsOptions}
            value={checkedBiomarkerPanelsList}
            onChange={onChangeBiomarkerPanels}
          />
        )}
      </div>
    </>
  );
};

export default BiomarkerPanels;
