// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.other-university {
  width: 100%; }
  @media screen and (max-width: 680px) {
    .other-university {
      width: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      display: flex; } }
  .other-university__title {
    font-size: 36px;
    font-weight: 600;
    color: 36px; }
    @media screen and (max-width: 680px) {
      .other-university__title {
        text-align: center; } }
  .other-university__items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; }
    @media screen and (max-width: 680px) {
      .other-university__items {
        width: 100%;
        flex-direction: column;
        align-items: center; } }
`, "",{"version":3,"sources":["webpack://./src/components/OtherUniversity/OtherUniversity.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;EACX;IAFF;MAGI,WAAW;MACX,sBAAsB;MACtB,mBAAmB;MACnB,uBAAuB;MACvB,aAAa,EAAA,EAqBhB;EAnBC;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW,EAAA;IACX;MAJF;QAKK,kBAAkB,EAAA,EAEtB;EACD;IACE,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,2BAA2B,EAAA;IAC3B;MALF;QAMM,WAAW;QACX,sBAAsB;QACtB,mBAAmB,EAAA,EAExB","sourcesContent":[".other-university {\n  width: 100%;\n  @media screen and (max-width: 680px) {\n    width: 100%;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n  }\n  &__title {\n    font-size: 36px;\n    font-weight: 600;\n    color: 36px;\n    @media screen and (max-width: 680px) {\n       text-align: center;\n      }\n  }\n  &__items{\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n    @media screen and (max-width: 680px) {\n        width: 100%;\n        flex-direction: column;\n        align-items: center;\n      }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
