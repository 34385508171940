import React, { useEffect, useState } from "react";
import ButtonGradient from "../../components/buttons/ButtonGradient";
import { Input, message } from "antd";
import "./ResetPass.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../utils/serverData";
import SendEmailPass from "../../components/SendEmailPass/SendEmailPass";
import axios from "axios";
import Error from "../../components/Error/Error";

const ResetPass = () => {
  const [password, setPassword] = useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [tokenValid, setTokenValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [errorMessages, setErrorMessages] = useState([]);

  const navigate = useNavigate();

  const getTokenFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get("token");
  };

  useEffect(() => {
    const validateToken = async () => {
      const token = getTokenFromUrl();

      if (token) {
        try {
          const response = await axios.get(
            `${BACKEND_URL}/auth//password/reset-password?token=${token}`
          );
          console.log("token response", response);
          if (response.data.message === "Token is valid") {
           
            setTokenValid(true);
          } else {
            setTokenValid(false);
          }
        } catch (error) {
          console.error("Error validating token:", error);
          setTokenValid(false);
        }
      }
    };

    validateToken();
  }, [location.search]);

  const handleSubmit = async () => {
    const token = getTokenFromUrl();

    if (password !== passwordNew) {
      message.error("Passwords do not match!");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${BACKEND_URL}/auth/password/reset-password`,
        {
          token: token,
          password: password,
          confirmPassword: passwordNew,
        }
      );

      setLoading(false);

      if (response.data === "Password reset successful") {
        message.success("Password reset successfully!");
        navigate(`/university/1`);
      } else {
        message.error(response.data.message || "Failed to reset password");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error resetting password:", error);
      if(error.response.data.errors){
        console.log("error messages",error.response.data.message)
        setErrorMessages(error?.response?.data?.errors[0]?.msg);
      }
    }
  };

  const Label = ({ text }) => {
    return <p className="sign-modal__label">{text}</p>;
  };
  return (
    <div className="reset">
      <div className="sign-modal__form">
        <h2>Reset Password</h2>
        {tokenValid ? (
          <div className="sign-modal__input-container">
            <Label text="New Password" />
            <Input.Password
              type="password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              placeholder="Please enter here..."
              className="sign-modal__passInput"
            />
            <Label text="Confirm Password" />
            <Input.Password
              type="password"
              value={passwordNew}
              onChange={({ target }) => setPasswordNew(target.value)}
              placeholder="Please enter here..."
              className="sign-modal__passInput"
            />
              <ul className="user-modal__list">
            <li>At least 8 characters—the longer, the better</li>
            <li>A mixture of both uppercase and lowercase letters</li>
            <li>A mixture of letters and numbers</li>
            <li>Inclusion of at least one special character.</li>
          </ul>
          <Error errorMessages={errorMessages} />
            <div className="user-modal__button-container">
              <ButtonGradient
                text={loading ? "Loading..." : "Submit"}
                handleClick={handleSubmit}
              />
            </div>
          </div>
        ) : (
          <>
            <p>Invalid or expired token.</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPass;
