import React from "react";
import PropTypes from "prop-types";
import "./Advantages.scss";
import { Skeleton } from "antd";

const Advantages = ({ advantages }) => {
  return (
    <div className="advantages">
      <h2 className="advantages__title">Advantages</h2>
      <ul className="advantages__list">
        {!advantages && <Skeleton variant="text" height={200} />}
        {advantages &&
          Object.entries(advantages).map(([key, value]) => (
            <li key={key} className="advantages__list-item">
              <strong>{key}:</strong> {value}
            </li>
          ))}
      </ul>
    </div>
  );
};

Advantages.propTypes = {
  advantages: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Advantages;
