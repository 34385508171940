import React from "react";
import { Checkbox } from "antd";
import { ChevronDown, ChevronRight, Info } from "lucide-react";
const CheckboxGroup = Checkbox.Group;

const AllWorkFlow = ({
  onChange,
  onCheckAllChange,
  indeterminate,
  checkAll,
  visible,
  handleVisibleChange,
  plainOptions,
  checkedList,
}) => {
  return (
    <>
      <div className="filter-sequencing__boxes">
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          <div className="filter-sequencing__boxTitle">
            All-in-One Workflows
          </div>
          <Info className="filter-sequencing__icon-info" size={16} />
        </Checkbox>
        <div onClick={handleVisibleChange} className="filter-sequencing__icon">
        {!visible && (<ChevronRight className="filter-sequencing__icon-arrow" />)}
        {visible && (<ChevronDown className="filter-sequencing__icon-arrow" />)}
        </div>
      </div>
      <div className="filter-sequencing__groups">
        {visible && (
          <CheckboxGroup
            options={plainOptions}
            value={checkedList}
            onChange={onChange}
          />
        )}
      </div>
    </>
  );
};

export default AllWorkFlow;
