import React, { useContext} from "react";
import { FileText } from "lucide-react";
import axios from "axios";
import { BACKEND_URL } from "../../../../utils/serverData";
import ButtonWhite from "../../../buttons/buttonWhite/ButtonWhite";
import ButtonGradient from "../../../buttons/ButtonGradient";
import ready from "../../../../assets/images/ready.png";
import { modalsContext } from "../../../../context/modalsContex";
import { universityInfoContext } from "../../../../context/universityInfoContext";
import { FaCheckSquare } from "react-icons/fa";

const IsReadyQuote = ({ closeModal, userMail }) => {
  const { itemLabInstrument, itemIndex } = useContext(universityInfoContext);
  const { token } = useContext(modalsContext);

  const handleGenerate = async (e) => {
    e.preventDefault();

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      const response = await axios.post(
        `${BACKEND_URL}/client/quote`,
        {
          options: [
            { optionId: itemLabInstrument, optionIndex: itemIndex, amount: 1 },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
            "Time-Zone": userTimeZone,
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      const contentDisposition = response.headers["content-disposition"];
      let fileName = "quote.pdf";
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }

      link.download = fileName;

      link.click();

      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="quote-modal">
      <img src={ready} alt="file-logo" className="quote-modal__img" />
      <h3 className="quote-modal__title">Your quote is ready</h3>

      <div>
        <div className="quote-modal__checkBox">
          <FaCheckSquare color="#00b0f0" />
          <p className="quote-modal__checkText">
            Send a quote to my email <b> {` ${userMail}`}</b>
          </p>
        </div>
      </div>

      <div className="quote-modal__btns">
        <div className="quote-modal__btnCancel">
          <ButtonWhite text="CLOSE" handleClick={closeModal} />
        </div>
        <div className="quote-modal__btnCont">
          <ButtonGradient
            text="DOWNLOAD QUOTE (PDF)"
            icon={<FileText className="quote-icon" size={20} />}
            handleClick={handleGenerate}
          />
        </div>
      </div>
    </div>
  );
};

export default IsReadyQuote;
