import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import axios from "axios";
import { BACKEND_URL } from "../../../utils/serverData";
import { universityInfoContext } from "../../../context/universityInfoContext";
import { filterInstrumentsContext } from "../../../context/filterInstrumentsContext";
import SearchInstruments from "../../../components/searchInstruments/SearchInstruments";

import "./InstrumentItems.scss";

import BreadMenu from "../../../components/menu/BreadMenu";
import TabInstrument from "../../../components/tabs/TabInstrument";
import AccessDeniedMessage from "../../../components/AccessDeniedMessage/AccessDeniedMessage";
import ReactGA from 'react-ga4'

const InstrumentItems = () => {
  const { uniId: id, facilityId: facId } = useParams();

  ReactGA.send({
    hitType: "pageview",
    page: `/university/${id}/facilities/${facId}`,
    title: "Facility Services Page"
  })

  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const {
    facilityName,
    setFacilityName,
    searchedInstruments,
    setSearchedInstruments,
  } = useContext(universityInfoContext);
  const {
    search,
    setSearch,
    setFilterData,
    setPriceRange,
    cleanFilters,
    queryParams,
  } = useContext(filterInstrumentsContext);

  const [view, setView] = useState("list");

  const [notFound, setNotFound] = useState(false);

  const [dataFetched, setDataFetched] = useState(false);


  useEffect(() => {
    const fetchUniInfo = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/client/facilities/${facId}`
        );

        setFacilityName(response.data.facility.name);
        // console.log(response.data)
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching universities data:", error);
        if (error.response.data.message === "Access denied") {
          setIsLoading(false);
          setIsAccessDenied(true);
        } else {
          setIsLoading(false);
        }
      }
    };

    fetchUniInfo();
  }, [facId, setFacilityName]);

  const onEnterPress = ({ keyCode }) => {
    if (keyCode === 13 && search.trim() !== "") {
      handleSendMessage(search);
    }
  };
  const onSearchChange = ({ target }) => {
    setSearch(target.value);
    if (target.value === "") {
      setNotFound(false);
      setSearchedInstruments([]);
    }
  };

  useEffect(() => {
    if (!dataFetched) {
      axios
        .get(`${BACKEND_URL}/client/university/${id}/filters`)
        .then((response) => {
          const data = response.data;

          setFilterData(data);
          setPriceRange({
            min: data.priceRange.min,
            max: data.priceRange.max,
          });
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setDataFetched(true);
        });
    }
  }, [dataFetched, id, setFilterData]);

  const handleSendMessage = async () => {
    try {
      const params = await queryParams();
      setIsLoading(true);
      let response = null;
      if (search) {
        response = await axios.get(
          `${BACKEND_URL}/client/facilities/${facId}/services?search=${search}&` +
            params
        );
      } else {
        response = await axios.get(
          `${BACKEND_URL}/client/facilities/${facId}/services?` + params
        );
      }

      setSearchedInstruments(response.data);

      if (response?.data?.length === 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
      // console.log("Search results:", response.data);
    } catch (error) {
      console.error("Error sending search query:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleButtonClick = () => {
    setIsFocused(true);
    handleSendMessage();
  };

  const handleViewChange = (selectedView) => {
    setView(selectedView);
  };
  const handleCleanFilters = async () => {
    cleanFilters();
    const response = await axios.get(
      `${BACKEND_URL}/client/facilities/${facId}/services`
    );
    setSearchedInstruments(response.data);
    if (response?.data?.length === 0) {
      setNotFound(true);
    } else {
      setNotFound(false);
    }
    setDataFetched(false);
  };

  return (
    <>
      <div className="instrum-container">
        <div className="instrument">
          <div className="instrument__menu">
            <BreadMenu title={facilityName} />
          </div>
          {isLoading ? (
            <Skeleton.Input />
          ) : (
            <h2 className="instrument__title">{facilityName}</h2>
          )}
          {isAccessDenied ? (
            <></>
          ) : (
            <SearchInstruments
              onFocus={() => setIsFocused(true)}
              value={search}
              onChange={onSearchChange}
              onKeyDown={onEnterPress}
              isFocused={isFocused}
              handleButtonClick={handleButtonClick}
              text="Search for machine or test name..."
            />
          )}
        </div>
      </div>
      {isAccessDenied ? (
        <AccessDeniedMessage />
      ) : (
        <TabInstrument
          searchedInstruments={searchedInstruments}
          view={view}
          notFound={notFound}
          handleViewChange={handleViewChange}
          handleSendFilters={handleSendMessage}
          handleCleanFilters={handleCleanFilters}
          isAccessDenied={isAccessDenied}
        />
      )}
      {/* <Subscrcibe /> */}
    </>
  );
};

export default InstrumentItems;
