import React, { useContext } from "react";
import { MoveRight, Home } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import "./ReviewOrder.scss";
import ButtonGradient from "../../components/buttons/ButtonGradient";

import CartContext from "../../context/cartContext";

import { universityInfoContext } from "../../context/universityInfoContext";
import { setDomianCookie } from "../../utils/cookiesUtils";
import ReactGA from 'react-ga4';

const ReviewOrder = () => {
  const { uniId: id } = useParams();

  ReactGA.send({
    hitType: "pageview",
    page: `/university/${id}/review`,
    title: "Order Review Page"
  })

  const navigate = useNavigate();
  const cartCtx = useContext(CartContext);
  const { orderId } = useContext(universityInfoContext);
  const handleNavigate = () => {
    navigate(`/university/${id}/cart`);
  };
  const handleNavigateOrder = () => {
    navigate(`/university/${id}/payment`);
  };
  const handleConfirm = () => {
    const redirectLink = 
    process.env.REACT_APP_ENV === "DEV" ? "https://admin-dev.gravl.io/orders" :
    process.env.REACT_APP_ENV === "PROD" ? "https://admin.gravl.io/orders" :
    process.env.REACT_APP_ENV === "DEMO" ? "https://admin-demo.gravl.io/orders" :
    "https://admin.gravl.io/orders"

    const token = localStorage.getItem("gravl_token");

    setDomianCookie("gravl_token", token, 5);

    window.open(redirectLink, "_blank");
  };

  const dataCart = cartCtx.items.map((item) => ({
    optionId: item.options.id,
    optionIndex: item.options.data.pricing.findIndex((e) => e.selected),
    amount: item.quantity,
  }));

  console.log("dataCart", dataCart);
  return (
    <div className="review">
      <div className="review-bread">
        <div className="review-bread__cart" onClick={handleNavigate}>
          Cart <MoveRight color="#C4C4C4" className="move-icon" />
        </div>
        <div className="review-bread__cart" onClick={handleNavigateOrder}>
          Order Payment <MoveRight color="#C4C4C4" className="move-icon" />
        </div>
        Order Review
      </div>
      <h2 className="review-title">Thank You For Your Order #{orderId}!</h2>
      <div className="review-text">
        <p>
          Please check your email or click{" "}
          <span onClick={handleConfirm} style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}>
            here
          </span>
          &nbsp;to fill out the sample submission form for the facility you
          selected.
        </p>
        <p>
          Once this is complete we will prompt you to print shipping labels and
          submit samples.
        </p>
      </div>
      {/* <ReviewCardOrder />
      <ReviewSummary /> */}

      <div className="review-btn">
        <ButtonGradient
          text="Go To Your Account"
          icon={<Home className="user-icon" size={20} />}
          handleClick={handleConfirm}
        />
      </div>
    </div>
  );
};

export default ReviewOrder;
