import React, { useContext, useEffect, useState } from "react";

import "./Cart.scss";
import CartItem from "../../components/cartItem/CartItem";
import TotalCart from "../../components/totalCartItem/TotalCart";
import CartContext from "../../context/cartContext";
import ReactGA from 'react-ga4';
import { useParams } from "react-router-dom";

const Cart = () => {
  const cartCtx = useContext(CartContext);

  const { uniId } = useParams();

  ReactGA.send({
    hitType: "pageview",
    page: `/university/${uniId}/cart`,
    title: "Cart Page"
  })

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    setTotalPrice(
      cartCtx.items.reduce((total, item) => {
        const selectedOption = item?.options?.data?.pricing.find(
          (p) => p.selected
        );

        return total + item.quantity * selectedOption?.price;
      }, 0)
    );
  }, [cartCtx]);



  return (
    <div className="cart">
      <h2>Your Cart ({cartCtx.items.length > 0 ? cartCtx.items.length : 0})</h2>
      <div className="cart-wrapp">
        <div className="cart-wrapp__list">
          {cartCtx.items.map((item, index) => (
            <CartItem
              key={index}
              item={item}
              index={index}
              onIncreas={() => cartCtx.changeAmount(index, 1)}
              onDecreas={() => cartCtx.changeAmount(index, -1)}
              handleRemoveItem={() => cartCtx.removeItem(index)}
              day={item.service.details.readyIn}
            />
          ))}
        </div>
        <div className="cart-wrapp__total">
          <TotalCart totalPrice={totalPrice} />
        </div>
      </div>
    </div>
  );
};

export default Cart;
