import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./ItemList.scss";
import Item from "./Item/Item";
import { BACKEND_URL } from "../../utils/serverData";
import { universityInfoContext } from "../../context/universityInfoContext";
import GridCardInstruments from "../gridCardinstruments/GridCardInstruments";
import NotFound from "../notFound/NotFound";

const ItemList = ({
  searchedInstruments,
  view,
  notFound,
 
}) => {
  const { uniId: id,facilityId: facId } = useParams();

  const [dataFetched, setDataFetched] = useState(false);
  const [device, setDevice] = useState([]);
  const { globUniversity, setFacilityIdName, setDeviceLength } = useContext(
    universityInfoContext
  );
  useEffect(() => {
    if (!dataFetched) {
      axios

        .get(`${BACKEND_URL}/client/facilities/${facId}/services`)
        .then((response) => {
          const updatedData = response.data.map((item) => ({
            ...item,
            id: item.service.id
          }));
         setDevice(updatedData);
          setDataFetched(true);
          setDeviceLength(response.data.length);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setDataFetched(true);
        });
    }
  }, [facId, dataFetched, setDevice, setDeviceLength]);
  useEffect(() => {
  
    const facility = globUniversity?.facilities?.find(
      (item) => item.id === parseInt(id)
    );
    if (facility) {
      setFacilityIdName(facility);
    }

  }, [globUniversity, id, setFacilityIdName]);

  return (
    <>
      {notFound ? (
        <NotFound />
      ) : view === "list" ? (
        <div className="itemList">
          {searchedInstruments.length > 0
            ? searchedInstruments.map((product, index) => (
                <Item
                  key={index}
                 
                  img={product.device.imageUrl}
                  title={product.service.name}
                  description={product.service.description}
                  price={product.minimalPrice}
                  product={product}
                  options={product.options}
                />
              ))
          
            : device.map((product, index) => (
                <Item
                  key={index}
                  product={product}
                 
                  img={product.device.imageUrl}
                  title={product.service.name}
                  description={product.service.description}
                  price={product.minimalPrice}
                  options={product.options}
                />
              ))}
        </div>
      ) : (
        <div className="grid-container">
          {searchedInstruments.length > 0
            ? searchedInstruments.map((product, index) => (
                <GridCardInstruments
                  key={index}
                  img={product.device.imageUrl}
                  title={product.service.name}
                  description={product.service.description}
                  price={product.minimalPrice}
                  options={product.options}
              
                  product={product}
                />
              ))
       
            : device.map((product, index) => (
                <GridCardInstruments
                  key={index}
                  img={product.device.imageUrl}
                  title={product.service.name}
                  description={product.service.description}
                  price={product.minimalPrice}
                  options={product.options}
                  
                  product={product}
                />
              ))}
        </div>
      )}
    </>
  );
};

export default ItemList;
