import React, { useContext, useState } from "react";
import { Info, Users, Cog } from "lucide-react";

import FilterInstruments from "../filterInstruments/FilterInstruments";
import ItemList from "../ItemList/ItemList";
import "./TabInstrument.scss";
import ViewSelector from "../viewselector/ViewSelector";
import { universityInfoContext } from "../../context/universityInfoContext";
import Team from "../Team/Team";
import About from "../About/About";
import ReactGA from "react-ga4";

const TabInstrument = ({
  searchedInstruments,
  view,
  notFound,
  handleViewChange,
  handleSendFilters,
  handleCleanFilters,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { deviceLength } = useContext(universityInfoContext);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="tabs-container">
      <div className="tabs">
        <div
          className={`tab ${activeTab === 0 ? "active" : ""}`}
          onClick={() => {
            ReactGA.event({
              category: "Button Click",
              action: "Clicked on Services",
              label: "Facility Services Page",
            });
            handleTabClick(0);
          }}
        >
          <Cog className="tab-icon" /> SERVICES (
          {searchedInstruments.length
            ? searchedInstruments.length
            : deviceLength || 0}
          )
        </div>
        <div
          className={`tab ${activeTab === 1 ? "active" : ""}`}
          onClick={() => {
            ReactGA.event({
              category: "Button Click",
              action: "Clicked on Team",
              label: "Facility Services Page",
            });
            handleTabClick(1);
          }}
        >
          <Users className="tab-icon" /> TEAM
        </div>
        <div
          className={`tab ${activeTab === 2 ? "active" : ""}`}
          onClick={() => {
            ReactGA.event({
              category: "Button Click",
              action: "Clicked on About",
              label: "Facility Services Page",
            });
            handleTabClick(2);
          }}
        >
          <Info className="tab-icon" /> ABOUT
        </div>
      </div>
      <div className="tab-content">
        {activeTab === 0 && (
          <>
            <ViewSelector
              handleViewChange={handleViewChange}
              view={view}
              title="Available Services"
            />
            <div className="tab-content__section">
              <FilterInstruments
                handleSendFilters={handleSendFilters}
                handleCleanFilters={handleCleanFilters}
              />
              <ItemList
                searchedInstruments={searchedInstruments}
                view={view}
                notFound={notFound}
              />
            </div>
          </>
        )}
        {activeTab === 1 && <Team />}
        {activeTab === 2 && <About />}
      </div>
    </div>
  );
};

export default TabInstrument;
