import React, { useContext, useEffect, useState } from "react";
import "./CartItem.scss";

import { Col, Popover, Row, Skeleton } from "antd";
import { ChevronDown, Settings, Trash2 } from "lucide-react";

import { months } from "../../utils/daysHelpper";
import OptionTypeCard from "../optionTypeCard/OptionTypeCard";
import CartContext from "../../context/cartContext";
import { formatPrice, formatReads } from "../../utils/currenceFormatter";
import { universityInfoContext } from "../../context/universityInfoContext";

const CartItem = ({ onIncreas, onDecreas, item, index, handleRemoveItem }) => {
  const cartCtx = useContext(CartContext);
  const { universityCurrency } = useContext(universityInfoContext);

  const [isLoading, setIsLoading] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [indexType, setIndexType] = useState(
    String(item.options.data.pricing.find((p) => p.selected) )
  );

  console.log(indexType)
  const [selectedReadType, setSelectedReadType] = useState(
    "indexType.toString()"
  );
  const [selectedPrice, setSelectedPrice] = useState();
  const [selectedOutput, setSelectedOutput] = useState();
  const [selectedReads, setSelectedReads] = useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, [setIsLoading]);

  useEffect(() => {
    setIndexType(
      String(item.options.data.pricing.findIndex((p) => p.selected))
    );
  }, [item]);

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const currentDate = new Date();
  const daysToAdd = item.service.details.readyIn; // Assuming {item.service.details.readyIn} gives the number of days

  const futureDate = new Date(
    currentDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000
  );

  // Format the date
  const formattedDate = `${
    months[futureDate.getMonth()]
  } ${futureDate.getDate()}, ${futureDate.getFullYear()}`;
  const title = <h2 className="option-popover__title">Available Options</h2>;

  const handleIndexChange = (selectedIndex) => {
   console.log(selectedIndex.id)

    cartCtx.changeIndex(index, selectedIndex);
  };
   useEffect(() => {
     if (item?.options?.data?.pricing?.[parseInt(indexType)]) {
       setIndexType(item?.options?.data?.pricing?.[parseInt(indexType)]);
     }
   }, [indexType, item?.options]);
  useEffect(() => {
    console.log( item?.options?.data?.pricing.filter((p)=>p.selected))
    console.log(item?.options?.data?.pricing?.[parseInt(indexType)])
    if (item?.options?.data?.pricing?.[parseInt(indexType)]) {
      const selectedOption = item.options.data.pricing[parseInt(indexType)];
      setSelectedReadType(
        selectedOption.data.readType || selectedOption.data.coverage
      );
      setSelectedPrice(selectedOption.price);
      setSelectedOutput(selectedOption.data.output);
      setSelectedReads(selectedOption.data.reads);
    }
  }, [item.options, indexType]);

  const content = (
    <div className="run-item">
      {title}
      <OptionTypeCard
        props={item}
        indexType={indexType}
        handleIndexChange={handleIndexChange}
        setSelectedReadType={setSelectedReadType}
        setSelectedPrice={setSelectedPrice}
        setSelectedOutput={setSelectedOutput}
        // setSelectedAmount={setSelectedAmount}
      />
    </div>
  );
  const seqType = item?.service?.type === "Sequencing" ? "seqType" : "";
  const allInType = item?.service?.type === "AllInOne" ? "allInType" : "";
  const libType = item?.service?.type === "LibraryPreparation" ? "libType" : "";
  const controlType =
    item?.service?.type === "QualityControlAndSampleHandling"
      ? "controlType"
      : "";
  const dataAnType = item?.service?.type === "DataAnalysis" ? "dataAnType" : "";
  const capitalizedType =
    item?.options?.data?.per?.type?.charAt(0).toUpperCase() +
    item?.options?.data?.per?.type?.slice(1);
  let capitalizedTypeWithPlural = capitalizedType;

  if (item?.options?.data?.per?.amount && item?.options.data.per.amount > 1) {
    capitalizedTypeWithPlural += "s";
  }
  const formatReadType = (readType) => {
    const parts = readType?.split("x");

    if (parts?.length === 2) {
      return `${parts[0]}x = ${parts[1]}`;
    }

    return readType; // Return the original if there's no 'x'
  };
  const formattedReadType = formatReadType(selectedReadType);
  return (
    <div className="cart-item">
      <div className="cart-item__header">
        <Col span={4}>
          {isLoading && <Skeleton.Image active />}
          {showImage && (
            <img
              src={item.device.imageUrl}
              alt="img"
              className="cart-item__img"
              onLoad={handleImageLoaded}
              style={{ display: isLoading ? "none" : "block" }}
            />
          )}
        </Col>
        <Col span={20}>
          <div className="cart-item__info">
            <h2 className="itemCard__title">{item.service.name}</h2>
            <p className="itemCard__description">{item.service.description}</p>
          </div>
          <div className="cart-item__option">
            {!dataAnType && (
              <Popover
                placement="bottomRight"
                content={content}
                arrow={false}
                fresh={true}
                destroyTooltipOnHide={true}
                className="itemDetails-instrument__option-popover"
                //    open={true}
                overlayKey={`popover-${item?.service?.id}`}
              >
                <div className="grid-instruments__option-text">
                  <Settings className="grid-instruments__option-icon" />
                  {item?.options?.data.pricing.length === 1
                    ? `${item?.options?.data.pricing.length} option`
                    : `${item?.options?.data.pricing.length} options`}
                  <ChevronDown className="grid-instruments__option-icon" />
                </div>
              </Popover>
            )}
          </div>
        </Col>
      </div>
      <Row className="cart-item__output">
        <Col span={9} className="cart-item__output">
          {seqType ? (
            <>
              {selectedReadType && (
                <Col span={7}>
                  <div className="cart-item__output--header">Read Type</div>
                  <div className="cart-item__output--result">
                    {selectedReadType}
                  </div>
                </Col>
              )}
              {selectedReads && (
                <Col span={6}>
                  <div className="cart-item__output--header">Reads</div>
                  <div className="cart-item__output--result">
                    {formatReads(selectedReads)}
                  </div>
                </Col>
              )}
              {selectedOutput && (
                <Col span={6}>
                  <div className="cart-item__output--header">Output</div>
                  <div className="cart-item__output--result">
                    {selectedOutput}Gb
                  </div>
                </Col>
              )}
              <Col span={6}>
                {item?.service.details.flowCell && (
                  <>
                    <div className="cart-item__output--header">Flow Cell</div>
                    <div className="cart-item__output--result">
                      {item?.service.details.flowCell}
                    </div>
                  </>
                )}
                {item?.service.details.lane && (
                  <>
                    <div className="cart-item__output--header">Lane</div>
                    <div className="cart-item__output--result">
                      {item?.service.details.lane}
                    </div>
                  </>
                )}
              </Col>
            </>
          ) : null}
          {allInType ? (
            <>
              {selectedReads && (
                <Col span={12}>
                  <div className="cart-item__output--header">Reads</div>
                  <div className="cart-item__output--result">
                    {formatReads(selectedReads)}
                  </div>
                </Col>
              )}
              {selectedReadType && (
                <Col span={12}>
                  <div className="cart-item__output--header">Coverage</div>
                  <div className="cart-item__output--result">
                    {selectedReadType}= {selectedOutput}Gb
                  </div>
                </Col>
              )}
              {/* {selectedOutput && (
                <Col span={12}>
                  <div className="cart-item__output--header">Output</div>
                  <div className="cart-item__output--result">
                    {selectedOutput}
                  </div>
                </Col>
              )} */}
            </>
          ) : null}
          {libType ? (
            <>
              <Col span={12}>
                <>
                  <div className="cart-item__output--header">Material</div>
                  <div className="cart-item__output--result">
                    {item?.service.details.material}
                  </div>
                </>
              </Col>
              <Col span={12}>
                <div className="cart-item__output--header">Min.Input</div>
                <div className="cart-item__output--result">
                  {item?.service.details.minInput}
                </div>
              </Col>
            </>
          ) : null}
          {controlType ? (
            <>
              <div className="itemDetails-instrument-types item"></div>
              <div className="itemDetails-instrument-types item"></div>
              <div className="itemDetails-instrument-types item"></div>
            </>
          ) : null}
          {dataAnType ? (
            <>
              <div className="itemDetails-instrument-types item"> </div>
              <div className="itemDetails-instrument-types item"></div>
              <div className="itemDetails-instrument-types item"></div>
            </>
          ) : null}
        </Col>
        <Col span={2}>
          <p className="cart-item__output--header">Price/Sample</p>
          <p className="cart-item__output--result">
            {formatPrice(selectedPrice, universityCurrency)}
          </p>
        </Col>
        <Col span={5} className="cart-item__colIncrease">
          <p className="cart-item__output--header">Quantity</p>
          <div className="cart-item__quantityBox">
            <div onClick={onDecreas} className="cart-item__quantity">
              -
            </div>
            <div className="cart-item__quantity">{item?.quantity}</div>
            <div onClick={onIncreas} className="cart-item__quantity">
              +
            </div>
          </div>
        </Col>
        <Col span={4}>
          <p className="cart-item__output--header">Est.Comp. Date</p>
          <p className="cart-item__output--result">{formattedDate}</p>
        </Col>
        <Col span={3}>
          <p className="cart-item__output--header">Item total</p>
          <p className="cart-item__output--result">
            {formatPrice(
              (item?.quantity * selectedPrice).toFixed(2),
              universityCurrency
            )}
          </p>
        </Col>
        <Col span={1}>
          <div className="cart-item__trash" onClick={handleRemoveItem}>
            <Trash2 color="#E83434" size={16} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CartItem;
