import React, { useContext, useState } from "react";
import { Slider, Input, InputNumber } from "antd";
import "./PriceRange.scss";
import { filterInstrumentsContext } from "../../../../context/filterInstrumentsContext";
import getSymbolFromCurrency from "currency-symbol-map";
import { universityInfoContext } from "../../../../context/universityInfoContext";
import { formatPriceRangeFilter } from "../../../../utils/currenceFormatter";

const PriceRange = () => {
  const { filterData, priceRange, setPriceRange } = useContext(
    filterInstrumentsContext
  );
  const { universityCurrency } = useContext(universityInfoContext);

  const [minInputValue, setMinInputValue] = useState(
    formatPriceRangeFilter(priceRange?.min ?? filterData?.priceRange?.min, universityCurrency)
  );

  const [maxInputValue, setMaxInputValue] = useState(
    formatPriceRangeFilter(priceRange?.max ?? filterData?.priceRange?.max, universityCurrency)
  );

  const [minInputFocused, setMinInputFocused] = useState(false);
  const [maxInputFocused, setMaxInputFocused] = useState(false);

  if (!filterData || !filterData.priceRange) {
    console.log(
      "filterData or filterData.priceRange is null:",
      filterData,
      filterData?.priceRange
    );
    return null;
  }


  const handleSliderChange = (values) => {
    setPriceRange({ min: values[0], max: values[1] });
  };
  const handleMinInputChange = (value) => {
    try {
      // if (value < priceRange.min) {
      //   value = filterData?.priceRange?.min;
      // }
      setPriceRange((prev) => ({
        ...prev,
        min: value,
      }));
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleMaxInputChange = (value) => {
    try {
      // if (value > priceRange.max) {
      //   value = filterData?.priceRange?.max;
      // }
      setPriceRange((prev) => ({
        ...prev,
        max: value,
      }));
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

 

  const symbol = getSymbolFromCurrency(universityCurrency);
  return (
    <div className="price-range">
      <Slider
        range
        min={filterData?.priceRange?.min}
        max={filterData?.priceRange?.max}
        value={[priceRange?.min, priceRange?.max]}
        onChange={handleSliderChange}
      />
      <div className="price-range__price">
        <p>{symbol}</p>
        <Input
          value={
            minInputFocused
              ? priceRange.min
              : formatPriceRangeFilter(priceRange?.min, universityCurrency)
          }
          onFocus={() => setMinInputFocused(true)}
          onBlur={() => setMinInputFocused(false)}
          onChange={(value) => {
            console.log(value)
              
            setMinInputValue(+value.target.value);
            handleMinInputChange(+value.target.value);
          }}
        />
        <p>{symbol}</p>

        <Input
          value={
            maxInputFocused
              ? priceRange.max
              : formatPriceRangeFilter(priceRange?.max, universityCurrency)
          }
          onFocus={() => setMaxInputFocused(true)}
          onBlur={() => setMaxInputFocused(false)}
          onChange={(value) => {
            console.log(value.target.value)

            setMaxInputValue(+value.target.value);
            handleMaxInputChange(+value.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default PriceRange;
