import React, {  useContext, useEffect } from "react";
import { Checkbox } from "antd";
import { ChevronDown, ChevronRight, Info } from "lucide-react";
import ReadRange from "../filtersTypes/readRange/ReadRange";
import OutPut from "../filtersTypes/outPut/OutPut";
import { filterInstrumentsContext } from "../../../context/filterInstrumentsContext";
import ReadLength from "../filtersTypes/readLength/ReadLength";
import InstrumentType from "../filtersTypes/instrumentType/InstrumentType";
import RunSize from "../filtersTypes/runSize/RunSize";
import ReadType from "../filtersTypes/readType/ReadType";

const Sequencing = ({ visibleSeq, handleVisibleSeq, sequencings }) => {
  const {
    filterData,
    setInstrumentType,
    laneChecked,
    setLaneChecked,
    cellChecked,
    setCellChecked,
    inputValueReadLength,
    setInputValueReadLength,
    setInputValueOutput,
    checkAllSeq,
    setCheckAllSeq,
    checkOutput,
    setCheckRangeOutput,
    visibleOutput,
    setVisibleRangeOutput,
    checkRange,
    setCheckRange,
    visibleRange,
    setVisibleRange,
    treeData,
    setTreeData,
    checkAllInstr,
    setCheckAllInstrum,
    setCheckedKeys,
    setCheckedSeqList,
    checkType,
    setCheckType,
    setCheckedListRead,
    inputLength,
    setInputLength,
    setCheckReadLength,
    visibleReadLength,
    setVisibleReadLength,
    setCheckAllRunSize,
  } = useContext(filterInstrumentsContext);

  const onCheckAllChangesSeq = (e) => {
    setCheckAllSeq(e.target.checked);
    setLaneChecked(e.target.checked);
    setCellChecked(e.target.checked);
    setInstrumentType([]);
    if (e.target.checked) {
      setInputValueReadLength({
        min: filterData?.readAmountRange?.min,
        max: filterData?.readAmountRange?.max,
      });
      setCheckRange(true);

      setInputLength({
        min: filterData?.readLengthRange?.min,
        max: filterData?.readLengthRange?.max,
      });

      setCheckReadLength(true);

      setInputValueOutput({
        min: filterData?.outputRange?.min,
        max: filterData?.outputRange?.max,
      });
      setCheckRangeOutput(true);

      setCheckAllInstrum(true);
      const allKeys = treeData.flatMap((node) => [node.key]);

      const checkedInstrumentTypes = allKeys.map((key) => {
        const [subtitle, model] = key.split("_");
        return { subtitle, model };
      });

      const groupedInstruments = checkedInstrumentTypes.reduce(
        (acc, { subtitle, model }) => {
          if (!acc[subtitle]) {
            acc[subtitle] = [];
          }
          acc[subtitle].push(model);
          return acc;
        },
        {}
      );

      const formattedResult = Object.entries(groupedInstruments).map(
        ([subtitle, models]) => {
          const formattedModels = models.join(", ");
          return `${subtitle} ${formattedModels}`;
        }
      );
      setCheckType(true);
      setCheckedListRead(readOptions[0]);
      setCheckedKeys(allKeys);
      setInstrumentType(formattedResult);
    } else {
      setInputValueReadLength({
        min: inputValueReadLength[0],
        max: inputValueReadLength[1],
      });
      setCheckRange(false);
      setInputLength({
        min: inputLength[0],
        max: inputLength[1],
      });
      setCheckReadLength(false);

      setInputValueOutput({
        min: inputLength[0],
        max: inputLength[1],
      });
      setCheckRangeOutput(false);

      setCheckAllInstrum(false);
      setCheckType(false);
      setCheckedListRead(null);
      setCheckedKeys([]);
      setInstrumentType([]);
    }
  };

  const onCheck = (checkedKeysValue) => {
    const checkedKeysVal = checkedKeysValue.length;

    const checkedInstrumentTypes = checkedKeysValue.reduce((acc, key) => {
      const [subtitle, model] = key.split("_");
      if (!acc[subtitle]) {
        acc[subtitle] = [];
      }
      acc[subtitle].push(model);

      return acc;
    }, {});

    const formattedResult = Object.entries(checkedInstrumentTypes).map(
      ([subtitle, models]) => {
        const formattedModels = models ? models.join(", ") : "";

        return `${subtitle} ${formattedModels}`;
      }
    );

    setInstrumentType(formattedResult);

    if (checkedKeysVal) {
      setCheckAllInstrum(true);
    } else {
      setCheckAllInstrum(false);
    }

    setCheckedKeys(checkedKeysValue);
  };

  useEffect(() => {
    const allComponentsChecked =
      laneChecked &&
      cellChecked &&
      checkRange &&
      checkOutput &&
      checkAllInstr &&
      checkType;

    const someComponentsChecked =
      laneChecked ||
      cellChecked ||
      checkRange ||
      checkOutput ||
      checkAllInstr ||
      checkType;

    setCheckedSeqList(someComponentsChecked);
    setCheckAllSeq(allComponentsChecked);
  }, [
    laneChecked,
    cellChecked,
    checkRange,
    checkOutput,
    checkAllInstr,
    setCheckedSeqList,
    setCheckAllSeq,
    checkType,
  ]);

  useEffect(() => {
    if (filterData.instrumentTypes) {
      const newData = Object.keys(filterData.instrumentTypes).map(
        (instrumentType) => {
          return {
            title: instrumentType,
            key: instrumentType,
          };
        }
      );

      setTreeData(newData);
    } else {
      setTreeData([]);
    }
  }, [filterData.instrumentTypes, setTreeData]);

  const handleCheckAllChange = (e) => {
    const checked = e.target.checked;
    setCheckAllInstrum(checked);

    if (checked) {
      const allKeys = treeData.flatMap((node) => [node.key]);

      const checkedInstrumentTypes = allKeys.map((key) => {
        const [subtitle, model] = key.split("_");
        return { subtitle, model };
      });

      const groupedInstruments = checkedInstrumentTypes.reduce(
        (acc, { subtitle, model }) => {
          if (!acc[subtitle]) {
            acc[subtitle] = [];
          }
          acc[subtitle].push(model);
          return acc;
        },
        {}
      );

      const formattedResult = Object.entries(groupedInstruments).map(
        ([subtitle, models]) => {
          const formattedModels = models.join(", ");
          return `${subtitle} ${formattedModels}`;
        }
      );

      setCheckedKeys(allKeys);
      setInstrumentType(formattedResult);
    } else {
      setCheckedKeys([]);
      setInstrumentType();
    }
  };

  //------Run Size

  useEffect(() => {
    setCheckAllRunSize(laneChecked || cellChecked);
  }, [laneChecked, cellChecked, setCheckAllRunSize]);

  const handleCheckAllRunSize = (e) => {
    const checked = e.target.checked;
    setCheckAllRunSize(checked);

    setLaneChecked(checked);
    setCellChecked(checked);
  };
  //-------Lane

  const onChangeLane = (e) => {
    setLaneChecked(e.target.checked);
  };

  //--------Cell
  const onChangeCell = (e) => {
    setCellChecked(e.target.checked);
  };
  //-------------------------Reads

  const handleVisibleRange = () => {
    setVisibleRange(!visibleRange);
  };

  const onChangeSlider = (values) => {
    setInputValueReadLength({ min: values[0], max: values[1] });
    setCheckRange(true);
  };
  const handleCheckboxChangeRange = (e) => {
    if (e.target.checked) {
      setInputValueReadLength({
        min: filterData?.readAmountRange?.min,
        max: filterData?.readAmountRange?.max,
      });

      setCheckRange(true);
    } else {
      setInputValueReadLength({
        min: inputValueReadLength[0],
        max: inputValueReadLength[1],
      });
      setCheckRange(false);
    }
    if (!e.target.checked) {
      setInputValueReadLength({
        min: inputValueReadLength[0],
        max: inputValueReadLength[1],
      });
    }
  };

  //------Read Length

  const handleVisibleReadLength = () => {
    setVisibleReadLength(!visibleReadLength);
  };
  const handleCheckboxChangeReadLength = (e) => {
    if (e.target.checked) {
      setInputLength({
        min: filterData?.readLengthRange?.min,
        max: filterData?.readLengthRange?.max,
      });

      setCheckReadLength(true);
    } else {
      setInputLength({
        min: inputLength[0],
        max: inputLength[1],
      });
      setCheckReadLength(false);
    }
    if (!e.target.checked) {
      setInputLength({
        min: inputLength[0],
        max: inputLength[1],
      });
    }
  };

  const onChangeReadLengthSlider = (values) => {
    setInputLength({ min: values[0], max: values[1] });
    setCheckReadLength(true);
  };

  //---------Output length

  const handleVisibleOutput = () => {
    setVisibleRangeOutput(!visibleOutput);
  };

  const onChangeOutput = (values) => {
    setInputValueOutput({ min: values[0], max: values[1] });
    setCheckRangeOutput(true);
  };
  const handleCheckboxChangeOutput = (e) => {
    if (e.target.checked) {
      setInputValueOutput({
        min: filterData?.outputRange?.min,
        max: filterData?.outputRange?.max,
      });
      setCheckRangeOutput(true);
    } else {
      setCheckRangeOutput(false);
    }
  };
  //---Read type
  const readOptions = filterData?.readTypes;

  const handleChangeRead = (e) => {
    setCheckedListRead(e.target.value);
    setCheckType(!!e.target.value);
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setCheckedListRead(readOptions[0]);

      setCheckType(true);
    } else {
      setCheckedListRead(null);

      setCheckType(false);
    }
  };

  return (
    <>
      <div className="filter-sequencing__boxes">
        <Checkbox
          indeterminate={sequencings}
          onChange={onCheckAllChangesSeq}
          checked={checkAllSeq}
        >
          <div className="filter-sequencing__boxTitle">Sequencing</div>
          <Info className="filter-sequencing__icon-info" size={16} />
        </Checkbox>
        <div onClick={handleVisibleSeq} className="filter-sequencing__icon">
          {!visibleSeq && (
            <ChevronRight className="filter-sequencing__icon-arrow" />
          )}
          {visibleSeq && (
            <ChevronDown className="filter-sequencing__icon-arrow" />
          )}
        </div>
      </div>
      <div className="filter-sequencing__groups">
        {visibleSeq && (
          <>
            <InstrumentType
              handleCheckAllChange={handleCheckAllChange}
              onCheck={onCheck}
            />
            <div className="filter-sequencing__flow">
              <RunSize
                handleCheckAllRunSize={handleCheckAllRunSize}
                onChangeLane={onChangeLane}
                onChangeCell={onChangeCell}
              />

              <ReadType
                readOptions={readOptions}
                handleChangeRead={handleChangeRead}
                handleCheckboxChange={handleCheckboxChange}
              />

              <ReadLength
                handleCheckboxChangeReadLength={handleCheckboxChangeReadLength}
                handleVisibleReadLength={handleVisibleReadLength}
                onChangeReadLengthSlider={onChangeReadLengthSlider}
              />
              <ReadRange
                handleCheckboxChangeRange={handleCheckboxChangeRange}
                handleVisibleRange={handleVisibleRange}
                visibleRange={visibleRange}
                onChangeSlider={onChangeSlider}
              />

              <OutPut
                onChangeOutput={onChangeOutput}
                handleCheckboxChangeOutput={handleCheckboxChangeOutput}
                handleVisibleOutput={handleVisibleOutput}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Sequencing;
