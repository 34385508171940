import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Popover, Skeleton } from "antd";
import { ChevronDown } from "lucide-react";
import { BACKEND_URL } from "../../utils/serverData";
import "./ItemDetails.scss";
import { modalsContext } from "../../context/modalsContex";
import { universityInfoContext } from "../../context/universityInfoContext";
import BreadMenu from "../../components/menu/BreadMenu";

import Specifications from "../../components/Specifications/Specifications";
import OtherUniversity from "../../components/OtherUniversity/OtherUniversity";
import ButtonsSection from "../../components/ButtonsSection/ButtonsSection";
import OptionTypeCard from "../../components/optionTypeCard/OptionTypeCard";
import CartContext from "../../context/cartContext";

import { formatPrice, formatReads } from "../../utils/currenceFormatter";
import ReactGA from 'react-ga4';


const ItemDetails = () => {
  const { uniId: id, serviceId: servId } = useParams();

  ReactGA.send({
    hitType: "pageview",
    page: `/university/${id}/service/${servId}`,
    title: "Service Details Page"
  })

  const navigate = useNavigate();
  const [showImage, setShowImage] = useState(false);
  const {
    facilityName,
    setFacilityName,
    setLabInstrumentData,
    universityCurrency,
  } = useContext(universityInfoContext);
  const { setLogIn, setSignUpOwnerModal } = useContext(modalsContext);
  const cartCtx = useContext(CartContext);
  const [details, setDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [cartLoad, setCartLoad] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/client/services/${servId}`
        );
        // console.log(response.data);
        setDetails({ ...response.data, id: response.data.service.id });
        setLabInstrumentData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, servId, setLabInstrumentData]);

  useEffect(() => {
    if (facilityName) {
      localStorage.setItem("facilityName", JSON.stringify(facilityName));
    }
  }, [facilityName]);

  useEffect(() => {
    const storedFacilityName = localStorage.getItem("facilityName");
    if (storedFacilityName) {
      setFacilityName(JSON.parse(storedFacilityName));
    }
  }, [setFacilityName]);

  const handleOpenModal = () => {
    setSignUpOwnerModal(true);
  };
  const handleOpenLogin = () => {
    setLogIn(true);
  };

  const [indexType, setIndexType] = useState(null);
  const [selectedReadType, setSelectedReadType] = useState("");
  const [selectedPrice, setSelectedPrice] = useState();
  const [selectedOutput, setSelectedOutput] = useState();
  const [selectedReads, setSelectedReads] = useState();
  const handleIndexChange = (index) => {
    setIndexType(index);
  };

  const title = <h2 className="option-popover__title">Available Options</h2>;

  useEffect(() => {
    if (details?.options?.data?.pricing) {
      setIndexType(details?.options?.data?.pricing[0].id);
    }
  }, [details.options]);

  useEffect(() => {
    if (details?.options?.data?.pricing.find((i) => i.id === indexType)) {
      const selectedOption = details?.options?.data?.pricing.find(
        (i) => i.id === indexType
      );
      setSelectedReadType(
        selectedOption.data.readType || selectedOption.data.coverage
      );
      setSelectedPrice(selectedOption.price);
      setSelectedOutput(selectedOption.data.output);

      setSelectedReads(selectedOption.data.reads);
    }
  }, [details.options, indexType]);

  const content = (
    <div className="run-item">
      {title}
      <OptionTypeCard
        props={details}
        indexType={indexType}
        handleIndexChange={handleIndexChange}
        setSelectedReadType={setSelectedReadType}
        setSelectedPrice={setSelectedPrice}
        setSelectedOutput={setSelectedOutput}
      />
    </div>
  );

  const seqType = details?.service?.type === "Sequencing" ? "seqType" : "";
  const allInType = details?.service?.type === "AllInOne" ? "allInType" : "";
  const libType =
    details?.service?.type === "LibraryPreparation" ? "libType" : "";
  const controlType =
    details?.service?.type === "QualityControlAndSampleHandling"
      ? "controlType"
      : "";
  const dataAnType =
    details?.service?.type === "DataAnalysis" ? "dataAnType" : "";

  const capitalizedType =
    details?.options?.data?.per?.type?.charAt(0).toUpperCase() +
    details?.options?.data?.per?.type?.slice(1);
  let capitalizedTypeWithPlural = capitalizedType;

  if (
    details?.options?.data?.per?.amount &&
    details?.options.data.per.amount > 1
  ) {
    capitalizedTypeWithPlural += "s";
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  function handleAddToCart() {
    setCartLoad(true);
    setTimeout(() => {
      cartCtx.addItem(details, +indexType, 1);
      setCartLoad(false);
    }, 500);
  }

  return (
    <div className="itemDetails">
      <BreadMenu
        title={facilityName}
        handleClick={() => navigate(-1)}
        thirdTitle={details?.service?.name}
        isLoading={isLoading}
      />
      <div className="itemDetails-instrument">
        <div className="itemDetails-instrument__imgSection">
          {isLoading && <Skeleton.Image active />}
          {showImage && (
            <img
              src={details?.device?.imageUrl}
              alt="img"
              onLoad={handleImageLoaded}
              style={{ display: isLoading ? "none" : "block" }}
            />
          )}
        </div>
        <div className="itemDetails-instrument__details">
          <div className="itemDetails-instrument__details wrapp">
            <div className="itemDetails-instrument__details serial">SKU:</div>
            <div className="itemDetails-instrument__details title">
              {details?.service?.name}
            </div>
            <p className="itemDetails-instrument__details info">
              {details?.service?.description}
            </p>
          </div>
          <div className="itemDetails-instrument__description">
            <div className="itemDetails-instrument__priceInfo">
              {formatPrice(selectedPrice, universityCurrency)}
              <span>
                /
                {details?.options?.data?.per?.amount !== 1 &&
                  details?.options?.data.per.amount}
                {capitalizedTypeWithPlural}
              </span>
            </div>

            <div className="itemDetails-instrument__allInfo">
              <div className="itemDetails-instrument__allInfo types">
                {seqType ? (
                  <>
                    <div className="itemDetails-instrument-types item">
                      <div className="itemDetails-instrument-types item-title">
                        Read Type
                      </div>
                      <div className="itemDetails-instrument-types item-info">
                        {selectedReadType}
                      </div>
                    </div>
                    {selectedReads && (
                      <div className="itemDetails-instrument-types item">
                        <div className="itemDetails-instrument-types item-title">
                          Reads
                        </div>
                        <div className="itemDetails-instrument-types item-info">
                          {formatReads(selectedReads)}
                        </div>
                      </div>
                    )}
                    {selectedOutput && (
                      <div className="itemDetails-instrument-types item">
                        <div className="itemDetails-instrument-types item-title">
                          Output
                        </div>
                        <div className="itemDetails-instrument-types item-info">
                          {selectedOutput}Gb
                        </div>
                      </div>
                    )}
                    <div className="itemDetails-instrument-types item">
                      {details?.service.details.flowCell && (
                        <>
                          <div className="itemDetails-instrument-types item-title">
                            Flow Cell
                          </div>
                          <div className="itemDetails-instrument-types item-info">
                            {details?.service.details.flowCell}
                          </div>
                        </>
                      )}
                      {details?.service.details.lane && (
                        <>
                          <div className="itemDetails-instrument-types item-title">
                            Lane
                          </div>
                          <div className="itemDetails-instrument-types item-info">
                            {details?.service.details.lane}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : null}
                {allInType ? (
                  <>
                    {selectedReads && (
                      <div className="itemDetails-instrument-types item">
                        <div className="itemDetails-instrument-types item-title">
                          Reads
                        </div>
                        <div className="itemDetails-instrument-types item-info">
                          {formatReads(selectedReads)}
                        </div>
                      </div>
                    )}
                    {selectedReadType && (
                      <div className="itemDetails-instrument-types item">
                        <div className="itemDetails-instrument-types item-title">
                          Coverage
                        </div>
                        <div className="itemDetails-instrument-types item-info">
                          {selectedReadType}= {selectedOutput}Gb
                        </div>
                      </div>
                    )}
                    {/* {selectedOutput && (
                      <div className="itemDetails-instrument-types item">
                        <div className="itemDetails-instrument-types item-title">
                          Output
                        </div>
                        <div className="itemDetails-instrument-types item-info">
                          {selectedOutput}
                        </div>
                      </div>
                    )} */}
                  </>
                ) : null}
                {libType ? (
                  <>
                    <div className="itemDetails-instrument-types item">
                      <>
                        <div className="itemDetails-instrument-types item-title">
                          Material
                        </div>
                        <div className="itemDetails-instrument-types item-info">
                          {details?.service.details.material}
                        </div>
                      </>
                    </div>
                    <div className="itemDetails-instrument-types item">
                      <div className="itemDetails-instrument-types item-title">
                        Min.Input
                      </div>
                      <div className="itemDetails-instrument-types item-info">
                        {details?.service.details.minInput}
                      </div>
                    </div>
                  </>
                ) : null}
                {controlType ? (
                  <>
                    <div className="itemDetails-instrument-types item"> </div>
                    <div className="itemDetails-instrument-types item"></div>
                    <div className="itemDetails-instrument-types item"></div>
                  </>
                ) : null}
                {dataAnType ? (
                  <>
                    <div className="itemDetails-instrument-types item"> </div>
                    <div className="itemDetails-instrument-types item"></div>
                    <div className="itemDetails-instrument-types item"></div>
                  </>
                ) : null}
              </div>
              {!dataAnType && (
                <Popover
                  placement="bottomRight"
                  content={content}
                  arrow={false}
                  fresh={true}
                  destroyTooltipOnHide={true}
                  className="itemDetails-instrument__option-popover"
                  //    open={true}
                  overlayKey={`popover-${details?.service?.id}`}
                >
                  <div className="itemDetails-instrument__option-text">
                    {/* <Settings className="grid-instruments__option-icon" /> */}
                    {details?.options?.data.pricing.length === 1
                      ? `${details?.options?.data.pricing.length} option`
                      : `${details?.options?.data.pricing.length} options`}
                    <ChevronDown className="grid-instruments__option-icon" />
                  </div>
                </Popover>
              )}
            </div>

            <div className="itemDetails__ready-title">
              Ready in:&nbsp;
              <span>
                {details?.service?.details?.readyIn}&nbsp;
                {details?.service?.details?.readyIn === 1 ? "day" : "days"}
              </span>
            </div>

            <ButtonsSection
              cartLoad={cartLoad}
              handlePurchase={handleAddToCart}
              handleOpenModal={handleOpenModal}
              handleOpenLogin={handleOpenLogin}
              serviceId={details?.service?.id}
              index={indexType}
              title={details?.service?.name}
              details={details}
            />
          </div>
        </div>
      </div>
      {/* <Specifications
        description={details?.service?.description}
        platform={details?.device?.specs?.platform}
        flowtype={details?.device?.specs?.flowCellType}
        flowNum={details?.device?.specs?.flowCellNumber}
        effect={details?.device?.specs?.effectiveReads}
        lane={details?.device?.specs?.laneNumber}
        temperature={details?.device?.specs?.temperature}
        humidity={details?.device?.specs?.humidity}
        pressure={details?.device?.specs?.atmosphericPressure}
      /> */}
      <OtherUniversity facilityName={facilityName} />
      {/* <Subscrcibe /> */}
    </div>
  );
};

export default ItemDetails;
