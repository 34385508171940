import "./AccessDeniedMessage.scss"

function AccessDeniedMessage() {
  return (
    <div className="access-denied-message">
      <h1 className="access-denied-message__title">Access Denied</h1>
      <p className="access-denied-message__text">
        University pilot version ended
      </p>
    </div>
  );
}

export default AccessDeniedMessage;
