import React from 'react';
import "./Error.scss"

const Error = ({ errorMessages }) => {
  return (
    <>
      {errorMessages?.length > 0 && (
        <div className="error__error-container">
          <p className="error__error-message">{errorMessages}</p>
        </div>
      )}
    </>
  );
};

export default Error;
