import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from "react-modal";
import './index.scss';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
const rootElement = document.getElementById("root");
Modal.setAppElement(rootElement);
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);


