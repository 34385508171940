import React, { useContext } from "react";
import Skeleton from "@mui/material/Skeleton";
import "./Publication.scss";
import ButtonWhite from "../../buttons/buttonWhite/ButtonWhite";
import { Newspaper } from "lucide-react";

import { universityInfoContext } from "../../../context/universityInfoContext";

const Publication = ({ aboutData, img, publicationsLink }) => {
  const { universityInfo } = useContext(universityInfoContext);
  return (
    <div className="publication">
      <div className="publication__container">
        {img ? (
          <img src={img} alt="img" className="publication__container-img" />
        ) : (
          <Skeleton variant="rectangular" width={400} height={200} />
        )}
      </div>

      <div className="publication__content">
        <h2 className="publication__title">Facility Publications</h2>
        {aboutData &&
          Object?.entries(aboutData)?.map(([value, text], index) => (
            <div className="publication__content-box" key={index}>
              <p className="publication__details">{value}</p>
              <p className="publication__text">{text}</p>
            </div>
          ))}

        <div className="publication__content-bnt">
          {publicationsLink && (
            <ButtonWhite
              text="View PUBLICATIONS"
              icon={<Newspaper className="quote-icon" size={20} />}
              handleClick={() => {
                console.log(publicationsLink);
                window.open(publicationsLink, "_blank");
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Publication;
