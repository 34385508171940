import React, { useContext } from "react";
import Skeleton from "@mui/material/Skeleton";
import "./AboutFacility.scss";
import { universityInfoContext } from "../../../context/universityInfoContext";

const AboutFacility = ({ aboutData, isLoading }) => {
  const { universityInfo } = useContext(universityInfoContext);
  return (
    <div className="about-facility">
      <div className="about-facility__content">
        <h2 className="about-facility__title">
          {aboutData?.title || (
            <Skeleton variant="text" width={200} height={20} />
          )}
        </h2>
        <p className="about-facility__subtitle">
          {aboutData?.content || (
            <Skeleton variant="text" width={400} height={60} />
          )}
        </p>
      </div>
      <div className="about-facility__container">
        {aboutData && aboutData?.image ? (
          <img
            src={aboutData?.image}
            alt="img"
            className="about-facility__container-img"
          />
        ) : (
          <Skeleton variant="rectangular" width={400} height={200} />
        )}
      </div>
    </div>
  );
};

export default AboutFacility;
