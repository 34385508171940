import React from "react";
import Skeleton from "@mui/material/Skeleton";
import "./AboutProducts.scss";

const AboutProducts = ({ aboutData, isLoading }) => {

  return (
    <div className="products-about">
      <h2 className="products-about__title">{aboutData?.title || <Skeleton variant="text" width={400} height={40} />}</h2>
      <div className="products-about__section">
        <div className="products-about__text">{aboutData?.content || <Skeleton variant="text" width={400} height={60} />} </div>
      </div>
    </div>
  );
};

export default AboutProducts;
