import { createContext, useState } from "react";

export const universityInfoContext = createContext();

export function UniversityProvider({ children }) {
  //TODO: Check if all the states are used
  //TODO: Rename some of the states to be more descriptive

  const [universityInfo, setUniversityInfo] = useState(null);
  const [universityCurrency, setUniversityCurrency] = useState(null);

  const [globUniversity, setGlobUniversity] = useState(null);
  const [facilityIdName, setFacilityIdName] = useState(null);
  const [facilityName, setFacilityName] = useState(null);
  const [searchedInstruments, setSearchedInstruments] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const [orderId, setOrderId] = useState();
  const [labInstrumentData, setLabInstrumentData] = useState(null);
  const [itemLabInstrument, setItemLabInstrument] = useState(null);
  const [itemLabInstrumentName, setItemLabInstrumentName] = useState(null);
  const [deviceLength, setDeviceLength] = useState([]);
  const [itemIndex, setItemIndex] = useState();
console.log(universityInfo?.idName)
  return (
    <universityInfoContext.Provider
      value={{
        globUniversity,
        setGlobUniversity,
        facilityIdName,
        setFacilityIdName,
        setFacilityName,
        facilityName,
        searchedInstruments,
        setSearchedInstruments,
        setOrderId,
        orderId,
        setLabInstrumentData,
        labInstrumentData,
        itemLabInstrument,
        setItemLabInstrument,
        itemLabInstrumentName,
        setItemLabInstrumentName,
        setDeviceLength,
        deviceLength,
        notFound,
        setNotFound,
        itemIndex,
        setItemIndex,
        universityInfo,
        setUniversityInfo,
        universityCurrency,
        setUniversityCurrency,
      }}
    >
      {children}
    </universityInfoContext.Provider>
  );
}
