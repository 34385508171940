// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery {
  width: 100%; }
  .gallery .image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 80vh !important;
    object-fit: cover;
    overflow: hidden;
    object-position: center center;
    border-radius: 16px; }
  .gallery .image-gallery-slides {
    border-radius: 16px; }
  .gallery .image-gallery-thumbnail .image-gallery-thumbnail-image {
    border-radius: 16px; }
  .gallery .image-gallery-thumbnail.active, .gallery .image-gallery-thumbnail:focus, .gallery .image-gallery-thumbnail:hover {
    border-radius: 20px; }
`, "",{"version":3,"sources":["webpack://./src/components/About/gallery/Gallery.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;EADb;IAIO,WAAW;IACX,YAAY;IACZ,2BAA0B;IAC1B,iBAAiB;IACjB,gBAAgB;IAChB,8BAA8B;IAC9B,mBAAmB,EAAA;EAV1B;IAaI,mBAAmB,EAAA;EAbvB;IAgBI,mBAAmB,EAAA;EAhBvB;IAmBI,mBAAmB,EAAA","sourcesContent":[".gallery {\n  width: 100%;\n\n   .image-gallery-slide img {\n       width: 100%;\n       height: auto;\n       max-height: 80vh!important;\n       object-fit: cover;\n       overflow: hidden;\n       object-position: center center;\n       border-radius: 16px;\n   }\n   .image-gallery-slides{\n    border-radius: 16px;\n   }\n   .image-gallery-thumbnail .image-gallery-thumbnail-image {\n    border-radius: 16px;\n   }\n   .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus,.image-gallery-thumbnail:hover{\n    border-radius: 20px;\n   }\n\n\n \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
