import React, { useContext } from "react";
import { Checkbox, Col, InputNumber, Row, Slider } from "antd";
import { ChevronDown, ChevronRight } from "lucide-react";
import "../FilterSequencing.scss";
import { filterInstrumentsContext } from "../../../../context/filterInstrumentsContext";

const ReadRange = ({
  visibleRange,
  handleVisibleRange,
  handleCheckboxChangeRange,

  onChangeSlider,

  
}) => {
  const { filterData, checkRange, inputValueReadLength,setInputValueReadLength } = useContext(
    filterInstrumentsContext
  );

  const handleMinInputChange = (value) => {
    setInputValueReadLength((prev) => ({
      ...prev,
      min: value,
    }));
  };

 
  const handleMaxInputChange = (value) => {
    setInputValueReadLength((prev) => ({
      ...prev,
      max: value,
    }));
  };
  
  return (
    <div className="filter-sequencing__readLengthBox">
      <div className="filter-sequencing__readLength">
        <Checkbox onChange={handleCheckboxChangeRange} checked={checkRange}>
          <div className="filter-sequencing__boxTitle">Reads (M)</div>
        </Checkbox>
        <div
          onClick={handleVisibleRange}
          className="filter-sequencing__iconRead"
        >
          {checkRange ? (
            visibleRange ? (
              <ChevronDown className="filter-sequencing__icon-arrow" />
            ) : (
              <ChevronRight className="filter-sequencing__icon-arrow" />
            )
          ) : null}
        </div>
      </div>

      {visibleRange && (
        <Col className="filter-sequencing__readLengthBox--visibleRange">
          <Col span={24}>
            {checkRange && (
              <Slider
                range
                min={filterData?.readAmountRange?.min}
                max={filterData?.readAmountRange?.max}
                onChange={onChangeSlider}
                value={
                  inputValueReadLength
                    ? [inputValueReadLength?.min, inputValueReadLength?.max]
                    : [
                        filterData.readAmountRange.min,
                        filterData.readAmountRange.max,
                      ]
                }
              />
            )}
          </Col>
          {checkRange && (
            <Row className="filter-sequencing__range">
              <InputNumber
                value={
                  inputValueReadLength?.min ?? filterData.readAmountRange?.min
                }
                onChange={(value) => handleMinInputChange(value)}
              />
              <InputNumber
                value={
                  inputValueReadLength?.max ?? filterData.readAmountRange.max
                }
                onChange={(value) => handleMaxInputChange(value)}
              />
            </Row>
          )}
        </Col>
      )}
    </div>
  );
};

export default ReadRange;
