import React from "react";
import { Checkbox } from "antd";
import { ChevronDown, ChevronRight, Info } from "lucide-react";
const CheckboxGroup = Checkbox.Group;
const SampleHand = ({
  sampleLength,
  onCheckAllChangeSample,
  checkAllSamples,
  handleVisibleSample,
  visibleSample,
  sampleOptions,
  checkedSampleList,
  onChangeSample,
}) => {
  return (
    <>
      <div className="filter-sequencing__boxes">
        <Checkbox
          indeterminate={sampleLength}
          onChange={onCheckAllChangeSample}
          checked={checkAllSamples}
        >
          <div className="filter-sequencing__boxTitle">
            QC & Sample handling
          </div>
          <Info className="filter-sequencing__icon-info" size={16} />
        </Checkbox>
        <div onClick={handleVisibleSample} className="filter-sequencing__iconSample">
        {!visibleSample && (<ChevronRight className="filter-sequencing__icon-arrow" /> )}
        {visibleSample && (<ChevronDown className="filter-sequencing__icon-arrow" /> )}
        </div>
      </div>
      <div className="filter-sequencing__groups">
        {visibleSample && (
          <CheckboxGroup
            options={sampleOptions}
            value={checkedSampleList}
            onChange={onChangeSample}
          />
        )}
      </div>
    </>
  );
};

export default SampleHand;
