import ButtonGradient from "../../../components/buttons/ButtonGradient";
import "./Banner.scss";

const Banner = (id) => {
  return (
    <div className="banner">
      <div className="banner-empty" />
      <div className="banner-section">
        <h2 className="banner-section__title">Want to offer your services?</h2>
        <p className="banner-section__text">
          Are you representing a facility and want to offer your services on
          GRAVL?
        </p>
        <p className="banner-section__text">
          Leave us a message by clicking on the button below. Our managers will
          contact you as soon as possible.
        </p>
        <div className="banner-btn">
          <a
            href="https://www.gravl.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            
            <ButtonGradient text="CONTACT US!" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Banner;
