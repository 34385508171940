import React, { useState } from "react";
import { InputField } from "../inputs/InputField";
import Error from "../Error/Error";
import { BACKEND_URL } from "../../utils/serverData";
import ButtonGradient from "../buttons/ButtonGradient";
import "./SendEmailPass.scss"
import axios from "axios";

const SendEmailPass = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false); 
  const [loading, setLoading] = useState(false); 
  const [errorMessage, setErrorMessage] = useState(""); 


  const handleSubmit = async () => {
  
    setLoading(true);
    setErrorMessage("");

    try {
      const response = await axios.post(`${BACKEND_URL}/auth/password/reset-request`, {
        email: emailAddress,
      });
      console.log("response", response)
      if (response.data.message === "Email sent") {
        setFormSubmitted(true); 
      } else {
        setErrorMessage(response.data.message || "Failed to send email");
      }
     
    } catch (error) {
      console.error("Error sending email:", error);
      if (error.response && error.response.data.message === "Account not found") {
        setErrorMessage("If the entered email is associated with an account, an email has been sent with instructions of how to reset your password.");
      } else {
        setErrorMessage("A user with this email was not found.");
      }
      
    } finally {
      setLoading(false);
    }
  };

  const Label = ({ text }) => {
    return <p className="sign-modal__label">{text}</p>;
  };
  return (
    <div className="sign-modal__form">
  {formSubmitted ? (
        <h2 className="sign-modal-textEmail">
        If the entered email is associated with an account, an email has been sent with instructions of how to reset your password.
        </h2>
      ) : (
        <>
          <h2 className="sign-modal-textEmail">Reset password with email</h2>
          <div className="sign-modal__input-container">
            <Label text="Email" />
            <InputField
              type="text"
              value={emailAddress}
              onChange={({ target }) => setEmailAddress(target.value)}
              placeholder="Please enter here..."
            />
            {errorMessage && <Error errorMessages={errorMessage} />}
            <div className="sign-modal__button-container">
              <ButtonGradient
                text={loading ? "Loading..." : "Submit"}
                handleClick={handleSubmit}
                disabled={loading || !emailAddress} 
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SendEmailPass;
