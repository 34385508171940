import React, { useContext, useEffect, useState } from "react";
import { Skeleton, Popover, Spin, InputNumber } from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ShoppingCart, Briefcase, ChevronDown, Settings } from "lucide-react";

import { modalsContext } from "../../context/modalsContex";
import { universityInfoContext } from "../../context/universityInfoContext";
import ButtonWhite from "../buttons/buttonWhite/ButtonWhite";
import OptionTypeCard from "../optionTypeCard/OptionTypeCard";
import CartContext from "../../context/cartContext";
import "./GridCardInstruments.scss";
import { formatPrice, formatReads } from "../../utils/currenceFormatter";

const GridCardInstruments = (props) => {
  const { uniId: id, facilityId: facId } = useParams();
  const navigate = useNavigate();
  const { token, setQuoteGenerateModal } = useContext(modalsContext);
  const { setItemLabInstrument, setItemLabInstrumentName, universityCurrency,setItemIndex, } =
    useContext(universityInfoContext);
  const cartCtx = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(true);
  const [showImage, setShowImage] = useState(false);
  const [itemAmount, setItemAmount] = useState(1);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);
  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  const [cartLoad, setCartLoad] = useState(false);
  const [indexType, setIndexType] = useState(null);
  const [selectedReadType, setSelectedReadType] = useState("");
  const [selectedPrice, setSelectedPrice] = useState();
  const [selectedOutput, setSelectedOutput] = useState();
  const [selectedReads, setSelectedReads] = useState();
  const [selectedAmount, setSelectedAmount] = useState();
  const [selectedNameControll, setSelectedNameControll] = useState();

  const handleIndexChange = (id) => {
    setIndexType(id);
  };
  const title = <h2 className="option-popover__title">Available Options</h2>;

  useEffect(() => {
    if (props?.options?.data?.pricing) {
      setIndexType(props?.options?.data?.pricing[0].id);
    }
  }, [props.options]);

  useEffect(() => {
    if (props?.options?.data?.pricing.find((i) => i.id === indexType)) {
      const selectedOption = props?.options?.data?.pricing.find(
        (i) => i.id === indexType
      );
      setSelectedReadType(
        selectedOption.data.readType || selectedOption.data.coverage
      );
      setSelectedPrice(selectedOption.price);
      setSelectedOutput(selectedOption.data.output);
      setSelectedAmount(selectedOption.data.amount);
      setSelectedReads(selectedOption.data.reads);
      setSelectedNameControll(selectedOption.data.name);
    }
  }, [props.options, indexType]);

  const content = (
    <div className="run-item">
      {title}
      <OptionTypeCard
        props={props}
        indexType={indexType}
        handleIndexChange={handleIndexChange}
        setSelectedReadType={setSelectedReadType}
        setSelectedPrice={setSelectedPrice}
        setSelectedOutput={setSelectedOutput}
        setSelectedAmount={setSelectedAmount}
        selectedAmount={selectedAmount}
        selectedNameControll={selectedNameControll}
      />
    </div>
  );
  const handleOpenQuoteModal = () => {
    setQuoteGenerateModal(true);
    setItemLabInstrument(props.options.id);
    setItemLabInstrumentName(props.title);
    setItemIndex(indexType);
  };

  const seqType = props.product.service.type === "Sequencing" ? "seqType" : "";
  const capitalizedType =
    props?.options?.data?.per?.type?.charAt(0).toUpperCase() +
    props?.options?.data?.per?.type?.slice(1);
  let capitalizedTypeWithPlural = capitalizedType;

  if (props?.options?.data?.per?.amount && props.options.data.per.amount > 1) {
    capitalizedTypeWithPlural += "s";
  }
  const allInType =
    props.product.service.type === "AllInOne" ? "allInType" : "";
  const libType =
    props.product.service.type === "LibraryPreparation" ? "libType" : "";
  const controlType =
    props.product.service.type === "QualityControlAndSampleHandling"
      ? "controlType"
      : "";
  const dataAnType =
    props.product.service.type === "DataAnalysis" ? "dataAnType" : "";

  const serviceType = props.product.service.type;

  const handleSelectLink = () => {
    if (props.isHome) {
      navigate(`/university/${id || 1}/service/${props.product.service.id}`);
    } else {
      navigate(
        `/university/${id || 1}/facilities/${facId}/service/${
          props.product.service.id
        }`
      );
    }
  };

  function handleAddToCart() {
    setCartLoad(true); // Set cartLoad to true to indicate loading state
    setTimeout(() => {
      cartCtx.addItem(props.product, +indexType, itemAmount);
      setCartLoad(false); // Set cartLoad back to false after adding item
    }, 500);
  }

  return (
    <div className="grid-instruments">
      <Link onClick={handleSelectLink}>
        {isLoading && <Skeleton.Image active />}
        {showImage && (
          <img
            src={props.img}
            alt="img"
            className="grid-instruments__img"
            onLoad={handleImageLoaded}
            style={{ display: isLoading ? "none" : "block" }}
          />
        )}
        <div>
          <h2 className="grid-instruments__title">{props.title}</h2>
          <p className="grid-instruments__description">
            {props.description.length > 100
              ? `${props.description.substring(0, 100)}...`
              : props.description}
          </p>
        </div>
      </Link>

      <div className="grid-instruments__option">
        {serviceType !== "DataAnalysis" && (
          <div className="itemCard__box-flow">
            <div className="itemCard__infoOption">
              <Popover
                placement="bottomRight"
                content={content}
                arrow={false}
                fresh={true}
                destroyTooltipOnHide={true}
                className="grid-instruments__option-popover"
                overlayKey={`popover-${props.id}`}
              >
                <div className="grid-instruments__option-text">
                  <Settings className="grid-instruments__option-icon" />
                  {props.options.data.pricing.length === 1
                    ? `${props.options.data.pricing.length} option`
                    : `${props.options.data.pricing.length} options`}
                </div>
                <ChevronDown className="grid-instruments__option-icon" />
              </Popover>
            </div>
          </div>
        )}
      </div>

      <Link onClick={handleSelectLink}>
        <div className="grid-instruments__box-types">
          {serviceType === "Sequencing" && (
            <>
              <div className="grid-instruments__box-types item">
                <div className="grid-instruments__box-types item-title">
                  Read Type
                </div>
                <div className="grid-instruments__box-types item-info">
                  {selectedReadType}
                </div>
              </div>
              <div className="grid-instruments__box-types item">
                {selectedReads && (
                  <>
                    <div className="grid-instruments__box-types item-title">
                      Reads
                    </div>
                    <div className="grid-instruments__box-types item-info">
                      {formatReads(selectedReads)}
                    </div>
                  </>
                )}
              </div>
              <div className="grid-instruments__box-types item">
                {selectedOutput && (
                  <>
                    <div className="grid-instruments__box-types item-title">
                      Output
                    </div>
                    <div className="grid-instruments__box-types item-info">
                      {selectedOutput}Gb
                    </div>
                  </>
                )}
              </div>
              <div className="grid-instruments__box-types item">
                {props.product.service.details.flowCell && (
                  <>
                    <div className="grid-instruments__box-types item-title">
                      Flow Cell
                    </div>
                    <div className="grid-instruments__box-types item-info">
                      {props.product.service.details.flowCell}
                    </div>
                  </>
                )}
                {props.product.service.details.lane && (
                  <>
                    <div className="grid-instruments__box-types item-title">
                      Lane
                    </div>
                    <div className="grid-instruments__box-types item-info">
                      {props.product.service.details.lane}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {serviceType === "AllInOne" && (
            <>
              <div className="grid-instruments__box-types item">
                {selectedReadType && (
                  <>
                    <div className="grid-instruments__box-types item-title">
                      Coverage
                    </div>
                    <div className="grid-instruments__box-types item-info">
                      {selectedReadType}={selectedOutput}Gb
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {serviceType === "LibraryPreparation" && (
            <>
              {props.product.service.details.material && (
                <div className="grid-instruments__box-types item">
                  <div className="grid-instruments__box-types item-title">
                    Material
                  </div>
                  <div className="grid-instruments__box-types item-info">
                    {props.product.service.details.material}
                  </div>
                </div>
              )}
              {props.product.service.details.minInput && (
                <div className="grid-instruments__box-types item">
                  <div className="grid-instruments__box-types item-title">
                    Min.Input
                  </div>
                  <div className="grid-instruments__box-types item-info">
                    {props.product.service.details.minInput}
                  </div>
                </div>
              )}
            </>
          )}
          {serviceType === "QualityControlAndSampleHandling" && (
            <>
              <div className="grid-instruments__box-types item"></div>
              <div className="grid-instruments__box-types item"></div>
            </>
          )}
          {serviceType === "DataAnalysis" && (
            <>
              <div className="grid-instruments__box-types item"></div>
              <div className="grid-instruments__box-types item"></div>
            </>
          )}
          {serviceType === "BiomarkerPanels" && (
            <>
              {props?.product?.service?.details["Product Number"] && (
                <div className="grid-instruments__box-types item">
                  <div className="grid-instruments__box-types item-title">
                    Product Code
                  </div>
                  <div className="grid-instruments__box-types item-info">
                    {props?.product?.service?.details["Product Number"]}
                  </div>
                </div>
              )}
              {props?.product?.service?.details["Manufacturer"] && (
                <div className="grid-instruments__box-types item">
                  <div className="grid-instruments__box-types item-title">
                    Manufacturer
                  </div>
                  <div className="grid-instruments__box-types item-info">
                    {props?.product?.service?.details["Manufacturer"]}
                  </div>
                </div>
              )}
              {props?.product?.service?.details["Samples / Full Panel"] && (
                <div className="grid-instruments__box-types item">
                  <div className="grid-instruments__box-types item-title">
                    Samples / Full Panel
                  </div>
                  <div className="grid-instruments__box-types item-info">
                    {props?.product?.service?.details["Samples / Full Panel"]}
                  </div>
                </div>
              )}
            </>
          )}
          <div className="grid-instruments__box-types item">
            <div className="grid-instruments__box-types item-title">Ready in</div>
            <div className="grid-instruments__box-types item-info">
              {props?.product?.service?.details?.readyIn}&nbsp;
              {props?.product?.service?.details?.readyIn === 1 ? "day" : "days"}
            </div>
          </div>
        </div>
        <div className="grid-instruments__box priceBox">
          <div className="grid-instruments__box price">
            <p className="grid-instruments__box price-title">
              Price/
              <>
                {props?.options?.data?.per?.amount !== 1 &&
                  props?.options?.data?.per?.amount + " "}
                {capitalizedTypeWithPlural}
              </>
            </p>
            <p className="grid-instruments__box price-info">
              {formatPrice(selectedPrice, universityCurrency)}
            </p>
          </div>
          <div className="grid-instruments__box available">
            Available in {props.product.service.details.readyIn}&nbsp;
            {props?.product?.service?.details?.readyIn === 1 ? "day" : "days"}
          </div>
        </div>
      </Link>
      <div className="grid-instruments__box-button">
        <div className="grid-instruments__box-amount">
          <InputNumber
            min={1}
            max={10}
            controls={true}
            defaultValue={1}
            style={{ width: 50 }}
            value={itemAmount}
            onChange={(amount) => setItemAmount(amount)}
          />
        </div>
        {cartLoad ? (
          <div className="itemCard__button add">
            <Spin />
            &nbsp; Adding..
          </div>
        ) : (
          <div className="grid-instruments__button" onClick={handleAddToCart}>
            <ShoppingCart className="grid-instruments__icon" />
            Add to Cart
          </div>
        )}
      </div>

      <div className="grid-instruments__generateQuote">
        <ButtonWhite
          text="GENERATE a QUOTE"
          icon={<Briefcase className="quote-icon" size={20} />}
          disabled={!token}
          handleClick={handleOpenQuoteModal}
        />
      </div>
    </div>
  );
};

export default GridCardInstruments;
