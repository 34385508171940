import React from "react";
import { Breadcrumb } from "antd";
import { useNavigate,useParams } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import "./BreadMenu.scss";

const BreadMenu = ({ title, handleClick, thirdTitle }) => {
  const { uniId: id } = useParams();
  const navigate = useNavigate();
  const handleClickHome = () => {
    navigate(`/university/${id || 1}`);
  };
  return (
    <div className="bread-menu">
      
      <Breadcrumb
        linkcolor="#1890ff"
        items={[
          {
            title: <span onClick={handleClickHome}>HOME</span>,
          },
          {
            title: (
              <span onClick={handleClick}>
                <ArrowRight
                  className="bread-menu__icon"
                  size={12}
                  strokeWidth={3}
                />
                {title}
              </span>
            ),
          },
          {
            title: thirdTitle && (
              <>
                <ArrowRight
                  className="bread-menu__icon"
                  size={12}
                  strokeWidth={3}
                />
                {thirdTitle}
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default BreadMenu;
