export const setDomianCookie = (name, value, minutes) => {
    const expirationDate = new Date();
    expirationDate.setMinutes(expirationDate.getMinutes() + minutes);
  
  
    const cookieValue =
      encodeURIComponent(value) +
      (minutes ? `; expires=${expirationDate.toUTCString()}` : "") +
      "; domain=gravl.io; path=/; SameSite=None; Secure";
  
    document.cookie = `${name}=${cookieValue}`;
  };