import React, { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import "./FacilityItem.scss";

const FacilityItem = ({ aboutCounter, isLoading }) => {
  const [activeTab, setActiveTab] = useState(2);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="facility-tab">
      <div className="tabs">
      {isLoading ? (
          // Render skeleton elements when isLoading is true
          Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="tab skeleton-tab">
              <Skeleton variant="text" width={80} height={100} />
              <Skeleton variant="text" width={80} height={100} />
            </div>
          ))
        ) : (
        aboutCounter &&
          Object.entries(aboutCounter)
            .slice(0, 5)
            .map(([key, value], index) => (
              <div
                key={index}
                className={`tab ${activeTab === index ? "active" : ""}`}
                onClick={() => handleTabClick(index)}
              >
                <div className="facility-tab__didjet">{value}</div>
                <div className="facility-tab__name">{key}</div>
              </div>
            ))
            )}
      </div>
    </div>
  );
};

export default FacilityItem;
