import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import { BACKEND_URL } from "../../utils/serverData";
import "./About.scss";
import FacilityItem from "./facilityItem/FacilityItem";
import AboutFacility from "./aboutFacility/AboutFacility";
import FunFacts from "./funFacts/FunFacts";
import AboutProducts from "./aboutProducts/AboutProducts";
import Publication from "./publications/Publication";

import Gallery from "./gallery/Gallery";
import { universityInfoContext } from "../../context/universityInfoContext";
import Advantages from "./advantages/Advantages";

const About = () => {
  const { uniId: id, facilityId: facId } = useParams();
  const [aboutData, setAboutData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { universityInfo } = useContext(universityInfoContext);

  useEffect(() => {
    const fetchUniInfo = async () => {
      try {
        const response = await axios.get(
          `${BACKEND_URL}/client/facilities/${facId}/about`
        );

        setAboutData(response.data);
        // console.log(response.data)
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching universities data:", error);
      }
    };

    fetchUniInfo();
  }, [facId]);

  return (
    <div className="about">
      {isLoading ? (
        <div className="about-box">
          <Skeleton animation="wave" height={60} />
        </div>
      ) : (
        <h2 className="about-title">{aboutData?.facility?.name}</h2>
      )}

      {/* <FacilityItem
        aboutCounter={aboutData?.instrumentCounter}
        isLoading={isLoading}
      /> */}
      <AboutFacility
        aboutData={aboutData?.facility?.about?.mainInformation}
        isLoading={isLoading}
      />
      {aboutData?.facility?.about?.advantages && (
        <Advantages
          advantages={aboutData?.facility?.about?.advantages}
          isLoading={isLoading}
        />
      )}
      <FunFacts
        aboutData={aboutData?.facility?.about?.facts}
        isLoading={isLoading}
      />
      {universityInfo?.idName !== "cobiomic" && (
        <AboutProducts
          aboutData={aboutData?.facility?.about?.additionalInformation}
          isLoading={isLoading}
        />
      )}

      <Publication
        aboutData={aboutData?.facility?.about?.publications}
        isLoading={isLoading}
        img={aboutData?.facility?.about?.publicationsImage}
        publicationsLink={aboutData?.facility?.about?.publicationsLink}
      />
      {universityInfo?.idName !== "cobiomic" && (
        <Gallery aboutData={aboutData?.facility?.about?.gallery} />
      )}
    </div>
  );
};

export default About;
