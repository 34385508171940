import React, { useContext, useState } from "react";
import { Checkbox, Tree } from "antd";
import { ChevronDown, ChevronRight } from "lucide-react";
import { filterInstrumentsContext } from "../../../../context/filterInstrumentsContext";

const InstrumentType = ({ handleCheckAllChange, onCheck }) => {
  const { checkAllInstr, checkedKeys, treeData } = useContext(
    filterInstrumentsContext
  );
  const [visibleTypes, setVisibleTypes] = useState(true);
  const handleVisibleChange = () => {
    setVisibleTypes(!visibleTypes);
  };
  const [selectedTitles, setSelectedTitles] = useState([]);

  const handleTitleClick = (key) => {
    let newSelectedTitles = [...selectedTitles];
    if (newSelectedTitles.includes(key)) {
      newSelectedTitles = newSelectedTitles.filter((title) => title !== key);
    } else {
      newSelectedTitles.push(key);
    }
    setSelectedTitles(newSelectedTitles);
    onCheck(newSelectedTitles);
  };

  return (
    <div>
      <div className="filter-sequencing__types">
        <Checkbox onChange={handleCheckAllChange} checked={checkAllInstr}>
          <div className="filter-sequencing__boxTitle">Instrument Type</div>
        </Checkbox>
        <div
          onClick={handleVisibleChange}
          className="filter-sequencing__iconInstrument"
        >
          {!visibleTypes && (
            <ChevronRight className="filter-sequencing__iconInstrument-arrowInstr" />
          )}
          {visibleTypes && (
            <ChevronDown className="filter-sequencing__iconInstrument-arrowInstr" />
          )}
        </div>
      </div>
      {visibleTypes && (
        <>
          <div className="filter-sequencing__groupsTree">
            <Tree
              checkable
              onCheck={(checkedKeys) => {
                setSelectedTitles([]);
                onCheck(checkedKeys);
              }}
              checkedKeys={checkedKeys}
              defaultExpandAll
              autoFocus
              onSelect={(selectedKeys, info) => {
                const {
                  node: { key },
                } = info;
                handleTitleClick(key);
              }}
              treeData={treeData}
              titleRender={(nodeData) => (
                <span onClick={() => handleTitleClick(nodeData.key)}>
                  {nodeData.title}
                </span>
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default InstrumentType;
