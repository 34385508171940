import React, { useContext, useState } from "react";
import { Checkbox, Radio } from "antd";
import { ChevronDown, ChevronRight } from "lucide-react";
import { filterInstrumentsContext } from "../../../../context/filterInstrumentsContext";

const ReadType = ({ readOptions, handleChangeRead, handleCheckboxChange }) => {
  const { checkedListRead, checkType } = useContext(filterInstrumentsContext);
  const [visibleRead, setVisibleRead] = useState(true);

  const handleVisibleChangeRead = () => {
    setVisibleRead(!visibleRead);
  };
  return (
    <>
      
      <div className="filter-sequencing__boxes">
        <Checkbox onChange={handleCheckboxChange} checked={checkType}>
          <div className="filter-sequencing__boxTitle">Read Type</div>
        </Checkbox>
        <div
          onClick={handleVisibleChangeRead}
          className="filter-sequencing__icon"
        >
          {!visibleRead && (
            <ChevronRight className="filter-sequencing__icon-arrow" />
          )}
          {visibleRead && (
            <ChevronDown className="filter-sequencing__icon-arrow" />
          )}
        </div>
      </div>
      <div className="filter-sequencing__groupsRead">
        {visibleRead && (
          <Radio.Group onChange={handleChangeRead} value={checkedListRead}>
            {readOptions.map((option) => (
              <Radio key={option} value={option}>
                {option === 2 && "Double"}
                {option === 1 && "Single"}
              </Radio>
            ))}
          </Radio.Group>
        )}
      </div>
    </>
  );
};

export default ReadType;
