import React from "react";
import { Search } from "lucide-react";
import "./SearchInstruments.scss";

const SearchInstruments = ({
  onFocus,
  value,
  onChange,
  onKeyDown,
  isFocused,
  handleButtonClick,
  text,
}) => {
  return (
    <div className="search-instrument search-input-container">
     <div onClick={handleButtonClick}>
          <Search size="20" className="search-icon" />
        </div>
      <input
        type="text"
        className="search-input"
        placeholder={text}
        onFocus={onFocus}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
     
    </div>
  );
};

export default SearchInstruments;
