import React, { useContext, useState } from "react";
import { Checkbox} from "antd";
import { ChevronDown, ChevronRight} from "lucide-react";
import { filterInstrumentsContext } from "../../../../context/filterInstrumentsContext";

const RunSize = ({ handleCheckAllRunSize, onChangeLane, onChangeCell }) => {
  const { checkAllRunSize, laneChecked, cellChecked } = useContext(
    filterInstrumentsContext
  );
  const [visibleRunSize, setVisibleRunSize] = useState(true);
  const handleVisibleRunSize = () => {
    setVisibleRunSize(!visibleRunSize);
  };
  return (
    <>
      <div className="filter-sequencing__boxes">
        <Checkbox onChange={handleCheckAllRunSize} checked={checkAllRunSize}>
          <div className="filter-sequencing__boxTitle">Run Size</div>
        </Checkbox>
        <div onClick={handleVisibleRunSize} className="filter-sequencing__icon">
          {!visibleRunSize && (
            <ChevronRight className="filter-sequencing__icon-arrow" />
          )}
          {visibleRunSize && (
            <ChevronDown className="filter-sequencing__icon-arrow" />
          )}
        </div>
      </div>
      <div className="filter-sequencing__groupsRead">
        {visibleRunSize && (
          <>
            <Checkbox onChange={onChangeLane} checked={laneChecked}>
              Lane
            </Checkbox>
            <Checkbox onChange={onChangeCell} checked={cellChecked}>
              Flow Cell
            </Checkbox>
          </>
        )}
      </div>
    </>
  );
};

export default RunSize;
