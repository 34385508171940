

export const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "15px",
      border: "none",
      background: "rgb(255, 255, 255)",
      boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.8"
    },
  };