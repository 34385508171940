import { createContext, useState } from "react";
import { constructQueryParams } from "../components/filterInstruments/helperFilter";

export const filterInstrumentsContext = createContext();

export function FilterInstrumentsProvider({ children }) {
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState({});
  const [checkedList, setCheckedList] = useState([]);
  const [checkedSeqList, setCheckedSeqList] = useState([]);
  const [checkedListLabs, setCheckedListLab] = useState([]);
  const [checkedSampleList, setCheckedSampleList] = useState([]);
  const [checkedBiomarkerPanelsList, setCheckedBiomarkerPanelsList] = useState([]);
  const [checkedListAnalys, setCheckedListAnalys] = useState([]);
  const [checkedInstrumentType, setInstrumentType] = useState([]);
  const [laneChecked, setLaneChecked] = useState(false);
  const [cellChecked, setCellChecked] = useState(false);
  const [inputValueReadLength, setInputValueReadLength] = useState(null);
  const [inputValueOutput, setInputValueOutput] = useState({ min: "", max: "" });
  const [availbDate, setAvailbDate] = useState({ min: "", max: "" });
  const [indexTypeAvailbDate, setIndexTypeAvailbDate] = useState("");
  const [visibleAllWorkFlow, setVisibleAllWorkFlow] = useState(true);
  const [visibleSeq, setVisibleSeq] = useState(false);
  const [visibleLabs, setVisibleLab] = useState(false);
  const [visibleSample, setVisibleSample] = useState(false);
  const [visibleBiomarkerPanels, setVisibleBiomarkerPanels] = useState(false);
  const [visibleAnalys, setVisibleAnalys] = useState(false);
  const [checkAllSeq, setCheckAllSeq] = useState(false);
  const [checkOutput, setCheckRangeOutput] = useState(false);
  const [visibleOutput, setVisibleRangeOutput] = useState(true);
  const [checkRange, setCheckRange] = useState(false);
  const [visibleRange, setVisibleRange] = useState(true);
  const [priceRange, setPriceRange] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [checkAllInstr, setCheckAllInstrum] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [checkType, setCheckType] = useState(false);
  const [checkedListRead, setCheckedListRead] = useState(null);
  const [inputLength, setInputLength] = useState(null);
  const [checkReadLength, setCheckReadLength] = useState(false);
  const [visibleReadLength, setVisibleReadLength] = useState(true);
  const [checkAllRunSize, setCheckAllRunSize] = useState(false)

  const cleanFilters = () => {
    setCheckedList([]);
    setCheckedSeqList([]);
    setCheckedListLab([]);
    setCheckedSampleList([]);
    setCheckedBiomarkerPanelsList([]);
    setCheckedListAnalys([]);
    setInstrumentType([]);
    setLaneChecked(false);
    setCellChecked(false);
    setInputValueReadLength(0);
    setInputValueOutput({ min: "", max: "" });
    setAvailbDate({ min: "", max: " " });
    setIndexTypeAvailbDate("");
    setVisibleAllWorkFlow(true);
    // setVisibleSeq(false);
    setVisibleLab(false);
    setVisibleSample(false);
    setVisibleBiomarkerPanels(false);
    setVisibleAnalys(false);
    setCheckAllSeq(false);
    setCheckRangeOutput(false);
    setVisibleRangeOutput(false);
    setCheckRange(false);
    setVisibleRange(false);
    setPriceRange(null);
    setTreeData([]);
    setCheckAllInstrum(false);
    setCheckedKeys([]);
    setCheckType(false);
    setCheckedListRead(null);
    setVisibleReadLength(false);
    setInputLength(0);
    setCheckReadLength(false);
    setCheckAllRunSize(false)
  };

  const queryParams = async () => {
    return constructQueryParams(
      filterData,
      priceRange,
      checkedList,
      checkedSeqList,
      checkedListLabs,
      checkedSampleList,
      checkedBiomarkerPanelsList,
      checkedListAnalys,
      checkedInstrumentType,
      laneChecked,
      cellChecked,
      inputValueReadLength,
      inputValueOutput,
      availbDate,
      indexTypeAvailbDate,
      checkedListRead,
      checkRange,
      checkReadLength,
      inputLength,
      checkOutput
    );
  };

  return (
    <filterInstrumentsContext.Provider
      value={{
        search,
        setSearch,
        filterData,
        setFilterData,
        checkedList,
        setCheckedList,
        checkedSeqList,
        setCheckedSeqList,
        checkedListLabs,
        setCheckedListLab,
        checkedSampleList,
        checkedBiomarkerPanelsList,
        setCheckedSampleList,
        setCheckedBiomarkerPanelsList,
        checkedListAnalys,
        setCheckedListAnalys,
        checkedInstrumentType,
        setInstrumentType,
        laneChecked,
        setLaneChecked,
        cellChecked,
        setCellChecked,
        inputValueReadLength,
        setInputValueReadLength,
        inputValueOutput,
        setInputValueOutput,
        availbDate,
        setAvailbDate,
        indexTypeAvailbDate,
        setIndexTypeAvailbDate,
        visibleAllWorkFlow,
        setVisibleAllWorkFlow,
        visibleSeq,
        setVisibleSeq,
        visibleLabs,
        setVisibleLab,
        visibleSample,
        visibleBiomarkerPanels,
        setVisibleSample,
        setVisibleBiomarkerPanels,
        visibleAnalys,
        setVisibleAnalys,
        cleanFilters,
        checkAllSeq,
        setCheckAllSeq,
        checkOutput,
        setCheckRangeOutput,
        visibleOutput,
        setVisibleRangeOutput,
        checkRange,
        setCheckRange,
        visibleRange,
        setVisibleRange,
        queryParams,
        priceRange,
        setPriceRange,
        treeData,
        setTreeData,
        checkAllInstr,
        setCheckAllInstrum,
        checkedKeys,
        setCheckedKeys,
        checkType,
        setCheckType,
        checkedListRead,
        setCheckedListRead,
        visibleReadLength,
        setVisibleReadLength,
        checkReadLength,
        setCheckReadLength,
        inputLength,
        setInputLength,
        checkAllRunSize, setCheckAllRunSize
      }}
    >
      {children}
    </filterInstrumentsContext.Provider>
  );
}
