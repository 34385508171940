import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Skeleton } from "antd";
import { BACKEND_URL } from "../../utils/serverData";
import "./Home.scss";
import MenuInstruments from "./menu/MenuInstruments";
import { universityInfoContext } from "../../context/universityInfoContext";
import SearchInstruments from "../../components/searchInstruments/SearchInstruments";

import FilterInstruments from "../../components/filterInstruments/FilterInstruments";
import ViewSelector from "../../components/viewselector/ViewSelector";

import Banner from "../MainLayout/Banner/Banner";
import NotFound from "../../components/notFound/NotFound";

import Item from "../../components/ItemList/Item/Item";
import GridCardInstruments from "../../components/gridCardinstruments/GridCardInstruments";
import { filterInstrumentsContext } from "../../context/filterInstrumentsContext";
import AccessDeniedMessage from "../../components/AccessDeniedMessage/AccessDeniedMessage";
import ReactGA from 'react-ga4';

const Home = () => {
  const { uniId: id } = useParams();

  ReactGA.send({
    hitType: "pageview",
    page: `/university/${id}`,
    title: "University Home Page"
  })

  const [isLoading, setIsLoading] = useState(true);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [view, setView] = useState("list");

  const {
    globUniversity,
    setGlobUniversity,
    searchedInstruments,
    setSearchedInstruments,
    notFound,
    setNotFound,
    universityInfo,
    setUniversityInfo,
  } = useContext(universityInfoContext);

  const { search, setSearch, setFilterData, setPriceRange,cleanFilters, queryParams } =
    useContext(filterInstrumentsContext);

  useEffect(() => {
    const fetchData = async () => {
      let uniId = id;

      try {
        const facilitiesResponse = await axios.get(
          //---checked backend url
          `${BACKEND_URL}/client/university/${uniId}/facilities`
        );

        setGlobUniversity(facilitiesResponse.data);
        // checked backend url
        const universityResponse = await axios.get(
          `${BACKEND_URL}/client/university/${uniId}`
        );

        setUniversityInfo(universityResponse.data);
        setIsLoading(false);
        // setIsLoadingInstruments(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response.data.message === "Access denied") {
          setIsLoading(false);
          setIsAccessDenied(true);
        } else {
          setIsLoading(false);
        }
      }
    };

    if (!globUniversity?.name) {
      fetchData();
    }
  }, [globUniversity?.name, id, setGlobUniversity]);

  //---------SEARCH----------//
  const onEnterPress = ({ keyCode }) => {
    if (keyCode === 13 && search.trim() !== "") {
      handleSendMessage(search);
    }
  };
  const onSearchChange = ({ target }) => {
    setSearch(target.value);
    if (target.value === "") {
      setNotFound(false);
      setSearchedInstruments([]);
    }
  };
  const handleSendMessage = async () => {
    let uniId = id;
    if (!id || isNaN(parseInt(id))) {
      uniId = 1;
    } else {
      uniId = id;
    }
    try {
      const params = await queryParams();
      setIsLoading(true);

      let response = null;
      if (search) {
        response = await axios.get(
          `${BACKEND_URL}/client/university/${uniId}/services?search=${search}&` +
            params
        );
      } else {
        response = await axios.get(
          `${BACKEND_URL}/client/university/${uniId}/services?` + params
        );
      }

      setSearchedInstruments(response.data);
      if (response.data.length === 0) {
        setNotFound(true);
      } else {
        setNotFound(false);
      }
      // console.log("Search results:", response.data);
    } catch (error) {
      console.error("Error sending search query:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleButtonClick = () => {
    setIsFocused(true);
    handleSendMessage();
  };
  const handleViewChange = (selectedView) => {
    setView(selectedView);
  };
  const foundtext =
    searchedInstruments.length === 1
      ? `${searchedInstruments.length} Found Result`
      : searchedInstruments.length > 1
      ? `${searchedInstruments.length} Found Results`
      : "Not Found";

  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    if (!dataFetched) {
      axios

        .get(`${BACKEND_URL}/client/university/${id}/filters`)
        .then((response) => {
          const data = response.data;
          console.log("instruments", data)
          setFilterData(data);
          setPriceRange({
            min: data.priceRange.min,
            max: data.priceRange.max,
          });
          setDataFetched(true);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setDataFetched(true);
        });
    }
  }, [id, dataFetched]);

  const handleCleanFilters = async () => {
    cleanFilters();
    setIsLoading(true);
    let uniId = id;
    if (!id || isNaN(parseInt(id))) {
      uniId = 1;
    } else {
      uniId = id;
    }
    const response = await axios.get(
      `${BACKEND_URL}/client/university/${uniId}/services`
    );

    setSearchedInstruments(response.data);
    if (response.data.length === 0) {
      setNotFound(true);
    } else {
      setNotFound(false);
    }
    setDataFetched(false);
    // window.location.reload();
  };

  return (
    <div className="home">
      <div className="home__banner">
        {isLoading ? (
          <Skeleton.Input />
        ) : (
          <h2 className="home__banner-title">{universityInfo.name}</h2>
        )}

        <p className="home__banner-text">
          Core Facilities & Shared Resources. Modern & Easy To Use Workflows For
          All Stakeholders
        </p>
        <SearchInstruments
          onFocus={() => setIsFocused(true)}
          value={search}
          onChange={onSearchChange}
          onKeyDown={onEnterPress}
          isFocused={isFocused}
          handleButtonClick={handleButtonClick}
          text="Search for..."
        />
      </div>
      {searchedInstruments.length === 0 && !notFound ? (
        <h2 className="home__banner__facility-title">Facilities</h2>
      ) : null}
      {searchedInstruments.length > 0 ? (
        <>
          <ViewSelector
            handleViewChange={handleViewChange}
            view={view}
            title={foundtext}
          />
          <div className="home__filter-wrapp">
            <FilterInstruments
              handleSendFilters={handleSendMessage}
              handleCleanFilters={handleCleanFilters}
            />
            {view === "list" ? (
              <div className="home__filter-list">
                {searchedInstruments.length > 0
                  ? searchedInstruments.map((product, index) => (
                      <Item
                        isHome={true}
                        key={index}
                        img={product.device.imageUrl}
                        title={product.service.name}
                        description={product.service.description}
                        price={product.minimalPrice}
                        options={product.options}
                        product={product}
                      />
                    ))
                  : null}
              </div>
            ) : (
              <div className="grid-container">
                {searchedInstruments.length > 0
                  ? searchedInstruments.map((product, index) => (
                      <GridCardInstruments
                        key={index}
                        img={product.device.imageUrl}
                        title={product.service.name}
                        description={product.service.description}
                        price={product.minimalPrice}
                        options={product.options}
                        product={product}
                        isHome={true}
                      />
                    ))
                  : null}
              </div>
            )}
          </div>
        </>
      ) : notFound ? (
        <NotFound />
      ) : isAccessDenied ? (
        <AccessDeniedMessage />
      ) : (
        <MenuInstruments isLoading={isLoading} />
      )}
      <Banner />
      {/* <Subscrcibe /> */}
    </div>
  );
};

export default Home;
