import { useContext, useState } from "react";
import Modal from "react-modal";
import axios from "axios";
import sign from "../../../assets/images/gravlogo.png";
import "./LoginModal.scss";
import { Checkbox, Input } from "antd";
import { modalStyles } from "../helpsModal";
import { modalsContext } from "../../../context/modalsContex";
import { BACKEND_URL } from "../../../utils/serverData";
import Error from "../../Error/Error";
import { InputField } from "../../inputs/InputField";
import ButtonGradient from "../../buttons/ButtonGradient";
import { KeyRound } from "lucide-react";
import SendEmailPass from "../../SendEmailPass/SendEmailPass";

const LoginModal = ({ navigate }) => {
  const {
    logIn,
    setLogIn,
    setSignUpOwnerModal,
    setToken,
    emailAddress,
    setEmailAddress,
    setUserProfile,
  } = useContext(modalsContext);

  const [passwordOwner, setPasswordOwner] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const [rememberToken, setRememberToken] = useState(false);
  const [resetPass, setResetPass] = useState(false);

  Modal.setAppElement("#root");

  function closeModal() {
    setLogIn(false);
    setResetPass(false);
  }

  const handleSave = () => {
    if (!emailAddress?.includes("@")) {
      alert("Please enter a valid email address");
      return;
    }

    const data = {
      email: emailAddress,
      password: passwordOwner,
      remember: rememberToken,
    };

    axios
      .post(`${BACKEND_URL}/auth/login`, data)
      .then((response) => {
        setErrorMessages([]);
        localStorage.setItem("gravl_token", response.data.token);

        setToken(response.data.token);

        localStorage.setItem("user_name", response.data.profile.firstName);
        localStorage.setItem("last_name", response.data.profile.lastName);
        localStorage.setItem("user_email", response.data.profile.details.email);
        // console.log(response.data)

        setUserProfile(response.data.profile);
        closeModal();
      })
      .catch((error) => {
        console.error("Error:", error);
        if (error.response.status === 404) {
          let errorShowMessage = error?.response?.data?.message;
          setErrorMessages(errorShowMessage);
        }

        setErrorMessages(error?.response?.data?.message);
      });
  };

  const handleAskSignUp = () => {
    setLogIn(false);
    setSignUpOwnerModal(true);
  };

  const Label = ({ text }) => {
    return <p className="sign-modal__label">{text}</p>;
  };

  const handleResetNavigate = () => {
    setResetPass(true);
  };
  return (
    <Modal
      isOpen={logIn}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <div className="sign-modal">
        <div className="sign-modal__close" onClick={closeModal}>
          X
        </div>
        <div className="sign-modal__header">
          <img src={sign} alt="logo" />{" "}
        </div>
        {!resetPass ? (
          <div>
            <h3 className="sign-modal__title">Sign in to Your Account</h3>
            <p className="sign-modal__provide">
              Please provide your sign in data below.
            </p>
            <div className="sign-modal__form">
              <div className="sign-modal__input-container">
                <Label text="Email" />
                <InputField
                  type="text"
                  value={emailAddress}
                  onChange={({ target }) => setEmailAddress(target.value)}
                  placeholder="Please enter here..."
                />
              </div>
              <div className="sign-modal__input-container">
                <Label text="Password" />
                {/* <ConfigProvider
              theme={{
                token: {
           
                  colorBgContainer: "none",
                  activeBg: "none",
                  addonBg: "none",
                },
              }}
            > */}
                <Input.Password
                  type="password"
                  value={passwordOwner}
                  onChange={({ target }) => setPasswordOwner(target.value)}
                  placeholder="Please enter here..."
                  className="sign-modal__passInput"
                />
                {/* </ConfigProvider> */}
              </div>
            </div>
            <Error errorMessages={errorMessages} />
            <div className="sign-modal__checkbox-container">
              <Checkbox
                onChange={(e) => setRememberToken(e.target.checked)}
                checked={rememberToken}
              >
                Remember me for 7 days
              </Checkbox>
              <div className="sign-modal__reset" onClick={handleResetNavigate}>
                Forgot Password?
              </div>
            </div>

            <div className="sign-modal__button-container">
              <ButtonGradient
                text="Sign In"
                icon={<KeyRound className="user-icon" size={20} />}
                handleClick={handleSave}
              />
            </div>
            <div className="sign-modal__askSign" onClick={handleAskSignUp}>
              Don’t have a GRAVL account? <span>Sign Up</span>
            </div>
          </div>
        ) : (
          <SendEmailPass />
        )}
      </div>
    </Modal>
  );
};

export default LoginModal;
