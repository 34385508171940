import React from "react";
import { Checkbox } from "antd";
import { ChevronDown, ChevronRight, Info } from "lucide-react";
const CheckboxGroup = Checkbox.Group;

const DataAnalysis = ({
  analysLength,
  onCheckAllChangeAnalys,
  checkAllAnalys,
  handleVisibleChangeAnalys,
  visibleAnalys,
  analysOptions,
  checkedListAnalys,
  onChangeAnalys,
}) => {
  return (
    <>
      <div className="filter-sequencing__boxes">
        <Checkbox
          indeterminate={analysLength}
          onChange={onCheckAllChangeAnalys}
          checked={checkAllAnalys}
        >
          <div className="filter-sequencing__boxTitle">
          Data Analysis
          </div>
          <Info className="filter-sequencing__icon-info" size={16} />
        </Checkbox>
        <div
          onClick={handleVisibleChangeAnalys}
          className="filter-sequencing__icon"
        >
         {!visibleAnalys && (<ChevronRight className="filter-sequencing__icon-arrow" />)}
           {visibleAnalys && (<ChevronDown className="filter-sequencing__icon-arrow" />)}
        </div>
      </div>
      <div className="filter-sequencing__groups">
        {visibleAnalys && (
          <CheckboxGroup
            options={analysOptions}
            value={checkedListAnalys}
            onChange={onChangeAnalys}
          />
        )}
      </div>
    </>
  );
};

export default DataAnalysis;
