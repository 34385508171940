import React from "react";
import search from "../../../src/assets/images/search.png";
import "./NotFound.scss";

const NotFound = () => {
  return (
    <div className="not-found">
      <img src={search} alt="search" />
      <h2>Nothing found.</h2>
      <p>
        It seems that the search does not provide any results. Please try use
        another keyword.
      </p>
    </div>
  );
};

export default NotFound;
