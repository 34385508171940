import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import "./MenuInstruments.scss";
import instrument from "../../../assets/images/it07.png";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import { Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { universityInfoContext } from "../../../context/universityInfoContext";

const MenuInstruments = ({ isLoading }) => {
  const { globUniversity } = useContext(universityInfoContext);
  const { uniId: id } = useParams();

  return isLoading ? (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
  ) : (
    <Row className="menu">
      {globUniversity?.facilities.map((item, index) => (
        <Col key={index} xs={24} sm={12} md={8} lg={6} xl={4}>
          <Link to={`/university/${id}/facilities/${item.id}`}>
            <div className="menu__section">
              <div className="menu__section-images">
                <div className="menu__section-images-title">{item?.name}</div>

                <div className="menu__section-box">
                  <div className="menu__section-arrow">
                    <ArrowRight className="menu__section-icon" />
                  </div>

                  <img src={instrument} alt="img" />
                </div>
              </div>
            </div>
          </Link>
        </Col>
      ))}
    </Row>
  );
};

export default MenuInstruments;
